import { Component, Inject, OnInit } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { CompanyService } from '@app/shared/services/company.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WalletTransactionTypes } from '@app/shared/constants';

@Component({
  selector: 'app-add-transaction',
  templateUrl: './add-transaction.component.html',
})
export class AddTransactionComponent implements OnInit {
  public loading: boolean = true;
  public company: Company;
  public transactionForm: FormGroup;

  constructor(
    public drawerRef: MtxDrawerRef<AddTransactionComponent>,
    @Inject(MTX_DRAWER_DATA) public data: { company: Company },
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private logger: LoggerService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    this.company = this.data.company;
    this.setupForm();
    this.loading = false;
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public createTransaction() {
    this.loading = true;
    const params = {
      transactionType: WalletTransactionTypes.ADMIN_ADJUSTMENT,
      chargedAmount: this.transactionForm.value.amount,
      walletAmount: this.transactionForm.value.amount,
      description: this.transactionForm.value.description,
    };
    this.companyService
      .createWalletTransaction(this.company.id, params)
      .subscribe(
        (transaction) => {
          this.logger.log('CREATE Wallet Transaction', transaction);
          this.loading = false;
          this.drawerRef.dismiss(true);
        },
        (error) => {
          this.logger.error('Error CREATE Wallet Transaction', error);
          this.snackBar.open('Problem saving transaction', '', {
            panelClass: 'error',
          });
          this.dismissDrawer();
        }
      );
  }

  private setupForm() {
    const companyWalletBalance = this.company?.walletBalance || 0;
    this.transactionForm = this.formBuilder.group({
      amount: [0, [Validators.required, this.negativeAmountValidator(companyWalletBalance)]],
      description: ['', [Validators.required]],
    });
  }

  private negativeAmountValidator(balance: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const amount = control.value;
      if (amount < 0 && Math.abs(amount) > balance) {
        return { insufficientBalance: true };
      }
      return null;
    };
  }
}
