<ng-container>
  <form [formGroup]="searchForm">
    <mat-card [ngClass]="{'mb--sm': paginatedAdjustments.length == 0 }">
      <mat-card-content class='pb--none'>
        <div class='row'>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Date range</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #rangePicker></mat-date-range-picker>

              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Status</mat-label>
              <mat-select formControlName="chargeStatus" name='status'>
                <mat-option *ngFor='let status of statusItems' value="{{ status.id }}">{{ status.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Courier</mat-label>
              <mat-select formControlName="provider" name='providerItem'>
                <mat-option *ngFor='let providerItem of providerItems' value="{{ providerItem.id }}">{{ providerItem.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Tracking number #</mat-label>
              <input matInput formControlName="trackingNumber" name="trackingNumber" type="text" maxlength="30">
            </mat-form-field>
          </div>
          <div class='column--12 column-md--8 align-right mt--xs'>
            <button mat-button color='primary' type='button' (click)='reset()' class='mr--md' [disabled]="searchForm.invalid || loading">Clear all</button>
            <button mat-flat-button color='primary' type='submit' (click)='search()' [disabled]="searchForm.invalid || loading">Search</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  <ng-container *ngIf="progress !== 0 && progress !== 100">
    <div class="row">
      <p class='text-larger mt--md'>Approving all adjustments…</p>
      <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    </div>
  </ng-container>
  <ng-container *ngIf='!loading, else isLoading'>
    <ng-container *ngIf="!loading && companyAdjustments.length > 0, else noAdjustments">
      <div class='column--12 column-md--6 align-left'>
        <button mat-flat-button color='primary' type='submit' class='mr--sm my--md' [disabled]="approveAllInProgress || !canEdit" (click)="approveAll()">Approve all</button>
      </div>
      <mat-card class='mt--xs mat-elevation-z0 mb--sm'>
        <mat-card-content>
          <div class='responsive-table'>
            <table mat-table [dataSource]="paginatedAdjustments" multiTemplateDataRows>
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef style='width: 5%'></th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  <button
                  mat-icon-button
                  color='primary'
                  class='mat-small-icon mx--xs'
                  (click)='toggleAdjustmentDetails(element)'
                ><mat-icon [ngClass]="element === expandedElement ? 'icon-minus' : 'icon-plus'"></mat-icon></button>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef style='width: 25%'>Name</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  {{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style='width: 25%'>Email Address</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  {{ element.account.email }}
                </td>
              </ng-container>
              <ng-container matColumnDef="shipments">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'># Shipments</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  {{ element.shipmentsCount }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalAdjustment">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'>TOTAL ADJUSTMENT</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  {{ element.totalAdjustmentsAmount | currency : 'CAD' : 'symbol-narrow' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'></th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  <span>
                    <button
                    mat-icon-button
                    color='primary'
                    class='mat-small-icon mx--xs'
                    mtxTooltip="Approve"
                    mtxTooltipPosition="above"
                    mtxTooltipClass="mtx-recon-tooltip"
                    *ngIf="element.canApproveBatchAdjustments && !approveAllInProgress"
                    [disabled]="!canEdit"
                    (click)="approveBatchRecon(element)"><mat-icon class='icon-check'></mat-icon></button>
                  </span>
                  <span>
                    <button
                    mat-icon-button
                    color='primary'
                    class='mat-small-icon mx--xs'
                    mtxTooltip="Ignore"
                    mtxTooltipPosition="above"
                    mtxTooltipClass="mtx-recon-tooltip"
                    *ngIf="element.canApproveBatchAdjustments && !approveAllInProgress"
                    [disabled]="!canEdit"
                    (click)="ignoreBatchRecon(element)"
                  ><mat-icon class='icon-ignore'></mat-icon></button>
                  </span>
                </td>
              </ng-container>
              <!-- Adjustment Details -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <div class="expanded-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <table mat-table [dataSource]="element.shipments" multiTemplateDataRows matSort>
                      <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef style='width: 5%'></th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          <button
                          mat-icon-button
                          color='primary'
                          class='mat-small-icon mx--xs'
                          (click)='toggleShipmentDetails(row)'
                        ><mat-icon [ngClass]="row === expandedShipmentElement ? 'icon-minus' : 'icon-plus'"></mat-icon></button>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef style='width: 10%'>Date</th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          {{ row.createdAt | date: 'E, d MMM' }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="provider">
                        <th mat-header-cell *matHeaderCellDef style='width: 15%'>Provider</th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          {{ getProviderName(row.provider) }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="paymentType">
                        <th mat-header-cell *matHeaderCellDef style='width: 12%'>payment type</th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          {{ getPaymentType(row.billingType.toUpperCase()) }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef style='width: 10%'>price</th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          {{ row.rates.initialCharge.amount | currency : 'CAD' : 'symbol-narrow' }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="adjustment">
                        <th mat-header-cell *matHeaderCellDef style='width: 10%'>adjustment</th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          {{ getAdjustmentValue(row) | currency : 'CAD' : 'symbol-narrow' }}
                          <mat-icon *ngIf="row.isPreviouslyDeclined" [matTooltip]="'Previously Declined'" mtxTooltipPosition="above" class="icon-info mat-icon-sm text--table-header ml--xs"></mat-icon>
                          <ng-template #popoverMergedShipments>
                            <div>
                              <div class="text--sm text-gray-optional">Parent shipment:</div>
                              <div class="mb-3 text--sm text-gray-dark">{{ row.tracking }}</div>
                              <div class="text--sm text-gray-optional">Children shipments:</div>
                              <div class="text--sm text-gray-dark">{{ row.mergedShipmentPopover }}</div>
                            </div>
                          </ng-template>
                          <mat-icon
                            class="icon-merge mat-icon-sm text--table-header ml--xs"
                            *ngIf="row.isMergedShipment"
                            [mtxTooltip]="popoverMergedShipments"
                            mtxTooltipPosition="above"
                            mtxTooltipClass="mtx-recon-tooltip">
                          </mat-icon>
                          <ng-template #popoverAssignedUnknownShipments>
                            <div class="text--sm text-gray-optional">Original tracking number</div>
                            <div class="row">
                              <div class="column-md--2">
                                <img [src]="row.providerImage" width="25px" height="25px"/>
                              </div>
                              <div class="column-md--7 mt--xxs text-gray-dark text--sm">
                                <span>{{ row.tracking }}</span>
                              </div>
                            </div>
                            <div class="text--sm text-gray-optional">New (assigned) tracking number</div>
                            <div class="row">
                              <div class="column-md--2">
                                <img [src]="row.providerImage" width="25px" height="25px"/>
                              </div>
                              <div class="column-md--7 mt--xxs text-gray-dark text--sm">
                                <span>{{ row.assignedUnknownShipmentPopover }}</span>
                              </div>
                            </div>
                          </ng-template>
                          <mat-icon
                          class="icon-assign mat-icon-sm text--table-header ml--xs "
                          *ngIf="row.hasAssignedUnknownShipment"
                          [mtxTooltip]="popoverAssignedUnknownShipments"
                          mtxTooltipPosition="above"
                          mtxTooltipClass="mtx-recon-tooltip">
                        </mat-icon>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="adjustmentDetails">
                        <th mat-header-cell *matHeaderCellDef style='width: 25%'></th>
                        <td mat-cell *matCellDef="let row" class='text--sm p--md'>
                          {{ row.adjustmentReasons }}
                          <mat-icon *ngIf="row.isMissingOrUnknownReason" [mtxTooltip]="getMissingOrUnknownReasonPopoverMessage(row)" mtxTooltipPosition="above" mtxTooltipClass="mtx-recon-tooltip" class="icon-info mat-icon-sm text--danger"></mat-icon>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="adjustmentAction">
                        <th mat-header-cell *matHeaderCellDef style='width: 12%'></th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          <ng-container *ngIf="!approveAllInProgress && !row.isMissingOrUnknownReason">
                            <button
                            *ngIf="row.canApproveAdjustment"
                            mat-icon-button
                            color='primary'
                            class='mat-small-icon mx--xs'
                            mtxTooltip="Approve"
                            mtxTooltipPosition="above"
                            mtxTooltipClass="mtx-recon-tooltip"
                            [disabled]="!canEdit"
                            (click)="approveRecon(element, row)"><mat-icon class='icon-check'></mat-icon></button>
                            <button
                            *ngIf="row.canIgnoreAdjustment"
                            mat-icon-button
                            color='primary'
                            class='mat-small-icon mx--xs'
                            mtxTooltip="Ignore"
                            mtxTooltipPosition="above"
                            mtxTooltipClass="mtx-recon-tooltip"
                            [disabled]="!canEdit"
                            (click)="ignoreRecon(element, row)"><mat-icon class='icon-ignore'></mat-icon></button>
                          </ng-container>
                          <ng-container *ngIf="row.isMissingOrUnknownReason && !approveAllInProgress">
                            <button mat-icon-button [matMenuTriggerFor]="reconMenu" aria-label="Approve actions" color='primary' [disabled]="!canEdit">
                              <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #reconMenu="matMenu">
                              <!-- Preview Button -->
                              <button mat-menu-item (click)="reassignOther(element, row)">
                                <span>Assign “Other” to known charge types</span>
                              </button>
                              <!-- Add Payment Button -->
                              <button mat-menu-item class='border-top' (click)="deleteOtherAndRecalculateAdjustment(element, row)">
                                <span class="text--danger">Remove Other</span>
                              </button>
                              <!-- Add Payment Button -->
                              <button mat-menu-item class='border-top' (click)="ignoreRecon(element, row)">
                                <span class="text--danger">Ignore the entire adjustment</span>
                              </button>
                            </mat-menu>
                          </ng-container>
                          <ng-template #processing>
                            <app-loading [isInline]="true"></app-loading>
                          </ng-template>
                        </td>
                      </ng-container>

                      <!-- Shipment Details -->
                      <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let shipment" [attr.colspan]="displayedNestedColumns.length">
                          <div class="expanded-detail" [@detailExpand]="shipment === expandedShipmentElement ? 'expanded' : 'collapsed'">
                            <div class='row mt--md mb--md p--sm'>
                              <div class='column--12 column-lg--8'>
                                <mat-card>
                                  <mat-card-content>
                                    <div class='row'>
                                      <div class='column--12 column-md--5'>
                                        <p class='my--none pb--xs border-bottom'><strong>Sender</strong></p>
                                        <p class='text--sm mt--xs mb--md lineheight-larger' notranslate>
                                          {{ shipment.senderInfo.name }}<span *ngIf='shipment.senderInfo.attentionTo'> / {{ shipment.senderInfo.attentionTo }}</span><br />
                                          <span *ngIf='shipment.senderInfo.email'>{{ shipment.senderInfo.email }}<br /></span>
                                          <span *ngIf='shipment.senderInfo.fullPhone'>{{ shipment.senderInfo.fullPhone }}<br /></span>
                                          <br />
                                          {{ shipment.senderInfo.address.line1 }}<br />
                                          <span *ngIf='shipment.senderInfo.address.line2 && shipment.senderInfo.address.line2.length > 0 '>{{ shipment.senderInfo.address.line2 }}<br /></span>
                                          {{ shipment.senderInfo.address.city }}, {{ shipment.senderInfo.address.province }}<br />
                                          {{ shipment.senderInfo.address.postalCode }}<br />
                                          <span *ngIf='shipment.senderInfo.formattedCostCenter'>Cost Center: {{ shipment.senderInfo.formattedCostCenter }}</span>
                                        </p>

                                        <p class='my--none pb--xs border-bottom'><strong>Recipient</strong></p>
                                        <p class='text--sm mt--xs mb--md lineheight-larger' notranslate>
                                          {{ shipment.recipientInfo.name }}<span *ngIf='shipment.recipientInfo.attentionTo'> / {{ shipment.recipientInfo.attentionTo }}</span><br />
                                          <span *ngIf='shipment.recipientInfo.email'>{{ shipment.recipientInfo.email }}<br /></span>
                                          <span *ngIf='shipment.recipientInfo.fullPhone'>{{ shipment.recipientInfo.fullPhone }}<br /></span>
                                          <br/>
                                          {{ shipment.recipientInfo.address.line1 }}<br />
                                          <span *ngIf='shipment.recipientInfo.address.line2 && shipment.recipientInfo.address.line2.length > 0 '>{{ shipment.recipientInfo.address.line2 }}<br /></span>
                                          {{ shipment.recipientInfo.address.city }}, {{ shipment.recipientInfo.address.province }}<br />
                                          {{ shipment.recipientInfo.address.postalCode }}<br />
                                          <span *ngIf='shipment.recipientInfo.buzzer'>Buzzer: {{ shipment.recipientInfo.buzzer }}</span>
                                        </p>

                                        <p class='my--none pb--xs border-bottom'><strong>Items</strong></p>
                                        <div class='mt--xs mb--md text--sm' notranslate *ngFor='let parcel of shipment.items, let i = index'>
                                          <ng-container *ngIf='!shipment.hasAdjustment, else hasAdjustments'>
                                            {{ parcel.length }}{{ parcel.linearUnits }} × {{ parcel.width }}{{ parcel.linearUnits }} × {{ parcel.height }}{{ parcel.linearUnits }}, {{ parcel.weight }}{{ parcel.weightUnits }}
                                          </ng-container>
                                          <ng-template #hasAdjustments>
                                            {{ parcel.length }}{{ parcel.linearUnits }} × {{ parcel.width }}{{ parcel.linearUnits }} × {{ parcel.height }}{{ parcel.linearUnits }}, {{ shipment.adjustedWeight }}{{ shipment.adjustedUnits }} (declared weight {{ parcel.weight }}{{parcel.weightUnits }})
                                          </ng-template>
                                        </div>

                                        <p class='my--none pb--xs border-bottom'><strong>Additional details</strong></p>
                                        <ng-container *ngIf='noReferences, else showReferences'>
                                          <div class='text--sm mt--xs mb--none' notranslate>N/A</div>
                                        </ng-container>
                                        <ng-template #showReferences>
                                          <div class='contact-info text--sm mt--xs mb--none'>
                                            <div *ngIf="additionalDetails.reference1"><span class='pr--xs'>Reference 1:</span> <span notranslate>{{additionalDetails.reference1}}</span></div>
                                            <div *ngIf="additionalDetails.reference2"><span class='pr--xs'>Reference 2:</span> <span notranslate>{{additionalDetails.reference2}}</span></div>
                                            <div *ngIf="additionalDetails.additionalNote"><span class='pr--xs'>Note:</span> <span notranslate>{{additionalDetails.additionalNote}}</span></div>
                                          </div>
                                        </ng-template>
                                      </div>
                                      <div class='column--12 column-md--7'>
                                        <p class='mt--none pb--xs border-bottom'><strong>Service</strong></p>
                                        <div class='row'>
                                          <div class='column--12'>
                                            <div class='service-summary'>
                                              <div *ngIf="shipment.service && shipment.service.provider, else noService">
                                                <div class='align-center'><img
                                                  [ngClass]="'summary-logo-' + shipment.providerCode"
                                                  [src]="shipment.providerLogoSrc"
                                                /></div>
                                                <div class='align-center semi-bold text--sm'>{{ shipment.service.name }}</div>
                                              </div>
                                              <ng-template #noService>
                                                <div class='text--sml'>No service information provided</div>
                                              </ng-template>
                                              <ng-container *ngIf='canViewShipmentDetails'>
                                                <div class='row border-bottom pt--sm pb--sm' *ngIf='shipment.service.type'>
                                                  <div class='column--6 text--sm'>Account type</div>
                                                  <div class='column--6 text--sm align-right'>
                                                    <span *ngIf="!shipment.service.thirdParty && shipment.service.type === 'ma'">Machool account</span>
                                                    <span *ngIf="!shipment.service.thirdParty && shipment.service.type === 'byoa'">Your account</span>
                                                    <span *ngIf="shipment.service.thirdParty">3rd party account</span>
                                                  </div>
                                                </div>
                                                <div class='border-bottom py--sm' *ngIf='shipment.service.thirdParty'>
                                                  <div class='text--sm'>Billed to 3rd party</div>
                                                  <ng-container [ngSwitch]="shipment.service.provider">
                                                    <ng-container *ngSwitchCase="shippingProviders.CANADA_POST">
                                                      <div class='row pt--sm'>
                                                        <div class='column--6 text--xs'><span class='pl--md'>Contract ID</span></div>
                                                        <div class='column--6 text--xs align-right'>{{ shipment.service.thirdParty.thirdPartyContractId || shipment.service.thirdParty.contractId }}</div>
                                                      </div>
                                                      <div class='row pt--sm'>
                                                        <div class='column--6 text--xs'><span class='pl--md'>Contract ID</span></div>
                                                        <div class='column--6 text--xs align-right'>{{ shipment.service.thirdParty.thirdPartyCustomerId || shipment.service.thirdParty.customerId }}</div>
                                                      </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                      <div class='row pt--sm'>
                                                        <div class='column--6 text--xs'><span class='pl--md'>Account number</span></div>
                                                        <div class='column--6 text--xs align-right'>{{ shipment.service.thirdParty.thirdPartyAccount || shipment.service.thirdParty.accountNumber }}</div>
                                                      </div>
                                                    </ng-container>
                                                  </ng-container>
                                                </div>
                                              </ng-container>
                                            </div>
                                            <div class='cost-summary-container text--sm' *ngIf='canViewShipmentDetails'>
                                              <div
                                                class='cost-summary'
                                                *ngFor="let rate of shipment.updatedRates; let i = index"
                                                [ngClass]="[
                                      ['thirdPartyInsurance', 'deliveryToPickupLocation'].includes(rate.type) ? 'third-party-insurance-row' : '',
                                      ['thirdPartyInsurance', 'deliveryToPickupLocation'].includes(rate.type) && !shipment.hasOptions ? 'mt-2' : '']"
                                              >
                                                <ng-container *ngIf="['thirdPartyInsurance', 'deliveryToPickupLocation'].includes(rate.type) && !shipment.hasOptions">
                                                  <div class='row mt--sm third-party-option-header'>
                                                    <div class='column--12 cost-summary-labels'>
                                                      <span>Options</span>
                                                    </div>
                                                  </div>
                                                </ng-container>
                                                <div
                                                  class='row cost-summary-rows mt--sm'
                                                  *ngIf="showSection(rate)"
                                                  [ngClass]="rate.adjustmentGroup ? 'has-adjustment' : ''"
                                                >
                                                  <ng-container *ngIf="rate.label === 'total'">
                                                    <div class='column--12'>
                                                      <div class='border-bottom mb--sm pt--sm'></div>
                                                    </div>
                                                  </ng-container>
                                                  <ng-container *ngIf="!rate.adjustmentGroup && showSection(rate)">
                                                    <div
                                                      class='column--12 cost-summary-labels'
                                                      [ngClass]="[i === (shipment.updatedRates.length - 1) ? 'column-md--5' : 'column-md--9', rate.adjustmentGroup ? 'btn-link' : '']"
                                                    >
                                                      <span [ngClass]="['thirdPartyInsurance', 'deliveryToPickupLocation'].includes(rate.type) ? 'text--xs' : ''" [innerHTML]='rate.label'></span>
                                                    </div>
                                                    <div
                                                      class='column--12 cost-summary-values align-right'
                                                      [ngClass]="i === (shipment.updatedRates.length - 1) ? 'column-md--7' : 'column-md--3'"
                                                    >
                                                      <span *ngIf="showAmount(rate)" [ngClass]="rate.type === 'thirdPartyInsurance' ? 'text--xs' : ''">{{ getRateValue(shipment, rate.amount) }}</span>
                                                      <span *ngIf="rate.valueIncluded">Included</span>
                                                      <span *ngIf="rate.label === 'taxes' && rate.amount === 0">{{ getRateValue(shipment, rate.amount) }}</span>
                                                      <span *ngIf="rate.label === 'discount'" [ngClass]="rate.amount < 0 ? 'text--danger' : ''">{{ getRateValue(shipment, rate.amount) }}</span>
                                                      <div *ngIf="rate.label === 'total'">
                                                        <strong>{{ getRateValue(shipment, rate.amount) }}</strong>
                                                        <small
                                                          class='cost-summary-labels'
                                                          *ngIf="rate.discount && rate.discount.amount > 0 && shipment.accountType === 'ma'",
                                                          [ngClass]="rate.discount.amount > 0 ? 'text-danger' : ''"
                                                        >{{ rate.discount.label }} {{ getRateValue(shipment, rate.discount.amount) }}</small>
                                                      </div>
                                                      <ng-container *ngIf="rate.type !== 'thirdPartyInsurance' && rate.infoMessage">
                                                        <button
                                                          mat-icon-button
                                                          aria-label="Info"
                                                          mtxTooltip="{{rate.infoMessage}}"
                                                          mtxTooltipPosition="above"
                                                          mtxTooltipClass="mtx-recon-tooltip"
                                                        >
                                                          <mat-icon class='icon-info'></mat-icon>
                                                        </button>
                                                      </ng-container>

                                                    </div>
                                                  </ng-container>
                                                  <ng-container *ngIf="rate.adjustmentGroup && showSection(rate)">
                                                    <div class='column--8 cost-summary-labels text--xs'>
                                                      <ng-container *ngIf="rate.stripeReceiptUrl, else noReceipt">
                                                        <a class='pl--md text--primary cursor-pointer' (click)="getReceipt(rate.stripeReceiptUrl)">
                                                          <span class="display-inline-block" [innerHTML]='rate.label'></span>
                                                        </a>
                                                      </ng-container>
                                                      <ng-template #noReceipt>
                                                        <span class='pl--md display-inline-block' [innerHTML]='rate.label'></span>
                                                      </ng-template>
                                                    </div>
                                                    <div class='column--4 cost-summary-value align-right text--xs'>
                                                      <span *ngIf="showAmount(rate)">-{{ getRateValue(shipment, rate.amount) }}</span>
                                                    </div>
                                                  </ng-container>
                                                  <div class='cost-summary-list mb--sm w-100 text--xs' *ngIf="rate.list && rate.list.length > 0">
                                                    <ng-container *ngFor="let item of rate.list; let idx = index">
                                                      <div class='row' [ngClass]="{ 'mt--sm': idx > 0 }" *ngIf="rate.label !== 'taxes' || (rate.label === 'taxes' && item.amount !== 0)">
                                                        <div class='column--8 cost-summary-labels'>
                                                          <span class='ml--md'>{{ rate.label !== 'taxes' ? (item.name) : (getTaxRatesLabel(item)) }}</span>
                                                        </div>
                                                        <div class='column--4 cost-summary-values align-right' *ngIf="item.amount !== 0">&nbsp;
                                                          <span
                                                            *ngIf="item.code === 'RETURN_LABEL'"
                                                            class='text--primary'
                                                            mtxTooltip="You will only be charged if your return label is used. The return shipment will be the same service without options"
                                                            mtxTooltipPosition="above"
                                                            mtxTooltipClass="mtx-recon-tooltip"
                                                          >{{ getRateValue(shipment, item.amount) }}</span>
                                                          <span *ngIf="item.code !== 'RETURN_LABEL'">{{ getRateValue(shipment, item.amount) }}</span>
                                                        </div>
                                                        <ng-container *ngIf="!shipment.service.thirdParty, else thirdParty">
                                                          <div class='column--4 cost-summary-values align-right' *ngIf="item.amount === 0">
                                                            <ng-container *ngIf="item.code === 'RETURN_LABEL', else included">
                                                            <span
                                                              class='text--primary'
                                                              *ngIf="!shipment.service.thirdParty"
                                                              mtxTooltip="You will only be charged if your return label is used. The return shipment will be the same service without options"
                                                              mtxTooltipPosition="above"
                                                              mtxTooltipClass="mtx-recon-tooltip"
                                                            >Selected</span>
                                                            </ng-container>
                                                            <ng-template #included>
                                                              {{ getRateValue(shipment, 'Included') }}
                                                            </ng-template>
                                                          </div>
                                                        </ng-container>
                                                        <ng-template #thirdParty>
                                                          <div class='column--4 cost-summary-values align-right'>-</div>
                                                        </ng-template>
                                                      </div>
                                                    </ng-container>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </mat-card-content>
                                </mat-card>
                              </div>
                              <div class='column--12 column-lg--4'>
                                <mat-card>
                                  <mat-card-content>
                                    <mat-nav-list class='py--none'>
                                      <mat-list-item mat-list-item *ngIf='shipment.tracking'>
                                        <div matListItemTitle (click)="openTracking(element, shipment)">
                                          <span class='text--primary-green'>{{ shipment.tracking }}</span>
                                          <span><mat-icon matListItemIcon class="text-gray-dark align-right mat-icon-sm ml--md" (click)="copyText(shipment.tracking); $event.stopPropagation();">content_copy</mat-icon></span>
                                        </div>
                                      </mat-list-item>
                                      <mat-list-item mat-list-item *ngIf="shipment.returnTrackings && shipment.returnTrackings.length > 0" (click)="openTracking(shipment, 'return')">
                                        <div matListItemTitle><span class='text--primary-green'>View Return Tracking Detail</span></div>
                                      </mat-list-item>
                                      <mat-divider></mat-divider>
                                      <mat-list-item mat-list-item (click)="getDocuments(shipment, 'labels')">
                                        <div matListItemTitle><span class='text--primary-green'>Download Shipping Label</span></div>
                                      </mat-list-item>
                                      <mat-list-item mat-list-item *ngIf='shipment.hasReturnLabel' (click)="getDocuments(shipment, 'return-labels')">
                                        <div matListItemTitle><span class='text--primary-green'>Download Return Label</span></div>
                                      </mat-list-item>
                                      <mat-list-item mat-list-item *ngIf='canViewManifests && shipment.manifest' (click)="getDocuments(shipment, 'manifests')">
                                        <div matListItemTitle><span class='text--primary-green'>Download Manifest</span></div>
                                      </mat-list-item>

                                      <mat-list-item *ngIf='canCreateReturns && !shipment.hasReturnLabel && isDomesticAccShipment' mat-list-item (click)="createReturnLabel(shipment)" [disabled]="!canEdit">
                                        <div matListItemTitle><span class='text--primary-green'>Create return label</span></div>
                                      </mat-list-item>

                                      <ng-container *ngIf='canEdit'>
                                        <mat-divider></mat-divider>
                                        <mat-list-item *ngIf='shipment.isCPNC' mat-list-item (click)="openCanadaPostReceiptModal(shipment)">
                                          <div matListItemTitle><span class='text--primary-green'>View Receipt</span></div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf='shipment.ediInvoice' mat-list-item (click)="openEdiInvoiceModal(shipment)">
                                          <div matListItemTitle><span class='text--primary-green'>View EDI Invoice</span></div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf="shipment.accountType !== 'byoa' && shipment.adminChargeDetails" mat-list-item (click)="openAdminChargeDetailsModal(shipment)">
                                          <div matListItemTitle><span class='text--primary-green'>View Admin Charge Details</span></div>
                                        </mat-list-item>
                                        <mat-list-item *ngFor="let invoice of shipment.adminShipmentInvoices" (click)="openInvoiceChargeDetailsModal(invoice)">
                                          <div matListItemTitle><span class='text--primary-green'>Download Invoice {{ invoice.invoiceNumber }}</span></div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf='shipment.isCI' mat-list-item (click)="getDocuments(shipment, 'commercial-invoices')">
                                          <div matListItemTitle><span class='text--primary-green'>Download Commercial Invoice</span></div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf='shipment.codReceipt' mat-list-item (click)="getDocuments(shipment, 'cod-receipts')">
                                          <div matListItemTitle><span class='text--primary-green'>Download
                                            {{ shipment.provider !== 'purolator' ? 'CoD Receipt' : 'ExpressCheque® Receipt' }}
                                        </span></div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf='shipment.codReturn' mat-list-item (click)="getDocuments(shipment, 'cod-return-labels')">
                                          <div matListItemTitle><span class='text--primary-green'>Download ExpressCheque® Return</span></div>
                                        </mat-list-item>
                                        <mat-list-item *ngIf='shipment.highValueReport' mat-list-item (click)="getDocuments(shipment, 'high-value-reports')">
                                          <div matListItemTitle><span class='text--primary-green'>Download High Value Report</span></div>
                                        </mat-list-item>
                                        <mat-list-item class="pr--none" *ngIf='shipment.hasThirdPartyInsurance' mat-list-item (click)="getThirdPartyInsuranceCertificate(shipment)">
                                          <div matListItemTitle>
                                            <span class='text--primary-green'>Download Insurance certificate</span>
                                            <app-loading [isInline]='true' *ngIf='gettingCertificate'></app-loading>
                                          </div>
                                        </mat-list-item>
                                      </ng-container>
                                    </mat-nav-list>
                                  </mat-card-content>
                                </mat-card>
                                <mat-card class='mt--md' *ngIf='canViewShipmentDetails'>
                                  <mat-card-content>
                                    <ng-container *ngIf='shipment.note'>
                                      <p class='mt--none pb--xs'><strong>Tags</strong></p>
                                      <div class='text--sm mt--xs mb--none' [title]='shipment.note' notranslate>{{ shipment.note }}</div>
                                    </ng-container>
                                    <ng-container>
                                      <mat-divider class="my--md" *ngIf='shipment.note'></mat-divider>
                                      <div class='pb--xs row row--valign-center'>
                                        <div class='column--6'><strong>Note (internal)</strong></div>
                                      </div>
                                      <div class='text--sm mt--xs mb--none' notranslate>
                                        <span *ngIf='shipment.csNote'>{{ shipment.csNote }}</span>
                                        <span *ngIf='!shipment.csNote'>N/A</span>
                                      </div>
                                    </ng-container>
                                  </mat-card-content>
                                </mat-card>
                              </div>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedNestedColumns"></tr>
                      <tr [ngClass]="{'text-danger': row.isMissingOrUnknownReason || getAdjustmentValue(row) < 0}" mat-row *matRowDef="let row; columns: displayedNestedColumns;"></tr>
                      <tr style="height: 0" mat-row *matRowDef="let shipment; columns:['expandedDetail']"></tr>
                    </table>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
              <tr style="height: 0" mat-row *matRowDef="let row; columns:['expandedDetail']"></tr>
            </table>
          </div>

          <mat-paginator
          (page)="pageChanged($event)"
          [length]='companyAdjustmentsCount'
          [pageSize]="itemsPerPage"
          [pageIndex]='currentPage - 1'
          [hidePageSize]='true'>
        </mat-paginator>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-template #noAdjustments>
      <div class='row justify-content-center'>
        <div class='column--12 align-center'>
          <div style='width: 220px; margin: 0 auto;'>
            <img src='{{ CDN_URL }}/admin/images/empty-history_animated.svg' />
            <p>No Adjustments found</p>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #isLoading>
    <div class='align-center mt--lg'>
      <app-loading></app-loading>
    </div>
  </ng-template>
</ng-container>
