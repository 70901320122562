import { Component, Inject, OnInit } from '@angular/core';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import {
  Company,
  CompanyInvoice,
} from '@app/pages/authenticated/pages/users/users.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BillingInvoicesService } from '@app/shared/services/billing-invoices.services';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
})
export class CreatePaymentComponent implements OnInit {
  public loading: boolean = true;
  public company: Company;
  public invoice: CompanyInvoice;
  public paymentForm: FormGroup;

  public id: number;
  public invoiceNumber: string;
  public paymentDate: Date | string = DateTime.now().toJSDate();
  public defaultTimezone = 'America/Toronto';

  constructor(
    public drawerRef: MtxDrawerRef<CreatePaymentComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: { company: Company; invoice: CompanyInvoice },
    private formBuilder: FormBuilder,
    private billingInvoicesService: BillingInvoicesService,
    private logger: LoggerService,
    private snackBar: MatSnackBar
  ) {}
  ngOnInit() {
    this.loading = true;
    this.company = this.data.company;
    this.invoice = this.data.invoice;
    this.paymentDate = DateTime.now().toJSDate();
    this.setupForm();
    this.loading = false;
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public create(): void {
    this.loading = true;
    const payment = this.paymentForm.value;
    const paymentDate = DateTime.fromJSDate(payment.paymentDate)
      .setZone(this.defaultTimezone)
      .startOf('day')
      .toISO();
    const queryData: any = {
      company: this.company,
      amount: payment.amount,
      paymentDate,
      description: payment.description,
      invoice: this.invoice,
    };

    this.billingInvoicesService
      .createInvoicePayment(this.invoice.id, queryData)
      .subscribe(
        (response: any) => {
          this.logger.log('Billing invoices - Create Payment', response);
          this.snackBar.open('Invoice payment created', '', {
            panelClass: 'success',
          });
          this.drawerRef.dismiss('complete');
        },
        (err: any) => {
          this.snackBar.open(
            'Problem creating invoice payment: ' + (err.message || err),
            '',
            { panelClass: 'error' }
          );
          this.logger.error('Billing invoices - Create Payment error', err);
          this.loading = false;
        }
      );
  }

  private setupForm(): void {
    const currentDue = parseFloat(this.invoice.balanceDue.toString()).toFixed(
      2
    );
    this.paymentForm = this.formBuilder.group({
      invoiceId: [this.invoice.id],
      companyId: [this.company.id],
      paymentDate: [this.paymentDate, [Validators.required]],
      amount: [
        currentDue, // force 2 decimal places
        [
          Validators.required,
          Validators.pattern(/^(-?.*[1-9])\d*\.?\d*$/),
          Validators.min(0),
          Validators.max(parseFloat(currentDue)),
        ],
      ], // allow both positive or negative numbers but not 0
      description: ['', []],
    });
  }
}
