import { Component, OnInit } from '@angular/core';
import { LoggerService } from '@app/shared/services/logger.service';
import * as _ from 'lodash';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { Dashboard, DashboardCard, DashboardService } from '@app/shared/services/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../../environments/environment';
import { ModifyCardComponent } from '@app/pages/authenticated/pages/dashboard/components/modify-card/modify-card.component';
import { PanelWidths, ProvinceList } from '@app/shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { AddDashboardComponent } from '@app/pages/authenticated/pages/dashboard/components/add-dashboard/add-dashboard.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  public loading: boolean = true;
  public user: AdminUser;
  public canEdit: boolean = false;
  public canEditZone: boolean = false;
  public hasZonesAvailable: boolean = true;
  public canEditCards: boolean = false;
  public CDN_URL: string = environment.OLD_CDN_URL + '/images/web/dashboard';

  public savedDashboards: Dashboard[] = [];
  public dashboards: Dashboard[] = [];
  public dashboardOptions: any[] = [];
  public selectedDashboard: { id: string; text: string };
  public dashboardCards: any = [];
  public dashboardCardOptions: any[] = [];
  public selectedLeftCard: { id: number; text: string };
  public selectedCenterCard: { id: number; text: string };
  public selectedRightCard: { id: number; text: string };
  public currentDashboard: Dashboard;

  private defaultId = 'Default';
  private selectedDashboardId = this.defaultId;
  private readonly provinces = ProvinceList;

  constructor(
    private logger: LoggerService,
    private dashboardService: DashboardService,
    private userService: UserService,
    private dialog: MatDialog,
    private drawer: MtxDrawer,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}
  ngOnInit() {
    this.loading = true;
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_DASHBOARD])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_DASHBOARD]);
            this.canEditZone = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_DASHBOARD_ZONES]);
            this.canEditCards = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_DASHBOARD_CARDS]);
            this.getDashboardCards();
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }

  public selectDashboard(dashboard): void {
    this.selectedDashboardId = dashboard.value;
    this.setupSelectedDashboard();
  }

  public selectDashboardCard(cardLocation: string, selectedCard: any): void {
    const selectedCardId: number = Number(selectedCard.value);
    const selectedCardObj = this.dashboardCards.find((card) => card.id === selectedCardId);
    if (selectedCardObj && selectedCardObj.id) {
      // Update the specified card location with the selected card;
      this.currentDashboard[cardLocation] = selectedCardObj;
    } else {
      this.currentDashboard[cardLocation] = null;
    }
  }

  public addDashboard(): void {
    if (this.canEditZone) {
      const drawerRef = this.drawer.open(AddDashboardComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { dashboards: this.dashboards },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.getDashboards();
        }
      });
    }
  }

  public updateDashboard(): void {
    if (this.canEditZone) {
      this.dashboardService.updateDashboard(this.currentDashboard.id, this.currentDashboard).subscribe(
        (updatedDashboard: Dashboard) => {
          this.snackBar.open('Dashboard was saved successfully', '', {
            panelClass: 'success',
          });
          this.logger.log('Udpate dashboard success', updatedDashboard);
          this.getDashboards();
        },
        (err) => {
          this.snackBar.open('Saving the dashboard failed', '', {
            panelClass: 'error',
          });
          this.logger.error('Update dashboard error', err);
        }
      );
    }
  }

  public addDashboardCard(cardLocation?: string): void {
    if (this.canEditCards) {
      const drawerRef = this.drawer.open(ModifyCardComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { card: undefined },
      });
      drawerRef.afterDismissed().subscribe((card?: DashboardCard): void => {
        if (card) {
          if (cardLocation) {
            this.currentDashboard[cardLocation] = card;
          }
          this.getDashboardCards();
          this.snackBar.open('Dashboard card was saved', '', {
            panelClass: 'success',
          });
        }
      });
    }
  }

  public editDashboardCard(card: DashboardCard): void {
    if (this.canEditCards) {
      // we don't want to allow default cards to be deleted;
      const isDefaultCard =
        this.currentDashboard.defaultCardLeft.id === card.id ||
        this.currentDashboard.defaultCardCenter.id === card.id ||
        this.currentDashboard.defaultCardRight.id === card.id;
      const dashboardsWithCard = this.savedDashboards.filter((dashboard) => {
        return (
          dashboard.cardLeft?.id === card.id ||
          dashboard.cardCenter?.id === card.id ||
          dashboard.cardRight?.id === card.id
        );
      });
      const cardIsUsed = (dashboardsWithCard || []).length > 0;

      const drawerRef = this.drawer.open(ModifyCardComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { card, isDefaultCard, cardIsUsed },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.getDashboardCards();
          this.snackBar.open('Dashboard card was saved', '', {
            panelClass: 'success',
          });
        }
      });
    }
  }

  private getDashboards(): void {
    this.loading = true;
    this.dashboardService.getDashboards().subscribe(
      (dashboards: Dashboard[]): void => {
        // sort the dashboards by povince, and ensure the 'Default' one is first
        this.dashboards = (dashboards || []).sort((a, b) => {
          if (a.id === 1) {
            return -1;
          }
          if (a.province < b.province) {
            return -1;
          }
          if (a.province > b.province) {
            return 1;
          }
          return 0;
        });
        this.savedDashboards = _.cloneDeep(this.dashboards);

        this.dashboardOptions = dashboards.map((dashboard) => {
          return { id: dashboard.province, text: dashboard.province };
        });
        this.logger.log('GET dashboards', dashboards);

        const takenProvinces: string[] =
          this.dashboards && this.dashboards.length > 0 ? this.dashboards.map((dashboard) => dashboard.province) : [];
        const provincesAvailable = this.provinces.filter((province) => !takenProvinces.includes(province.id));
        this.hasZonesAvailable = (provincesAvailable || []).length > 0;

        this.setupSelectedDashboard();
      },
      (err): void => {
        this.snackBar.open('Could not find dashboards.', '', {
          panelClass: 'error',
        });
        this.logger.error('GET dashboards error', err);
      }
    );
  }

  private setupSelectedDashboard(): void {
    this.loading = true;
    this.selectedDashboard = this.dashboardOptions.find((option) => option.id === this.selectedDashboardId);
    this.currentDashboard = this.dashboards.find((dashboard) => dashboard.province === this.selectedDashboardId);

    if (this.selectedDashboardId !== this.defaultId && !this.dashboardCardOptions.find((card) => card.id === null)) {
      this.dashboardCardOptions = [{ id: null, text: this.defaultId }, ...this.dashboardCardOptions];
    }
    this.selectedLeftCard =
      this.currentDashboard && this.currentDashboard.cardLeft
        ? {
            id: Number(this.currentDashboard.cardLeft.id),
            text: this.currentDashboard.cardLeft.name,
          }
        : undefined;
    this.selectedCenterCard =
      this.currentDashboard && this.currentDashboard.cardCenter
        ? {
            id: Number(this.currentDashboard.cardCenter.id),
            text: this.currentDashboard.cardCenter.name,
          }
        : undefined;
    this.selectedRightCard =
      this.currentDashboard && this.currentDashboard.cardRight
        ? {
            id: Number(this.currentDashboard.cardRight.id),
            text: this.currentDashboard.cardRight.name,
          }
        : undefined;
    this.loading = false;
  }

  private getDashboardCards(): void {
    this.loading = true;
    this.dashboardService.getDashboardCards().subscribe(
      (dashboardCards) => {
        this.dashboardCards = (dashboardCards || []).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.dashboardCardOptions = this.dashboardCards.map((dashboardCard) => {
          return { id: dashboardCard.id, text: dashboardCard.name };
        });
        this.getDashboards();
        this.logger.log('GET dashboard cards', dashboardCards);
      },
      (err) => {
        this.snackBar.open('Could not find dashboard cards.', '', {
          panelClass: 'error',
        });
        this.logger.error('GET dashboard cards error', err);
      }
    );
  }
}
