import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import * as FileSaver from 'file-saver';
import { DateFormats, ShippingProviders, SupportedAirports } from '@app/shared/constants';
import { ShipmentsService } from '@app/shared/services/shipments.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { ViewDocumentComponent } from '@app/pages/authenticated/components/view-document/view-document.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-manifests',
  templateUrl: './manifests.component.html',
})
export class ManifestsComponent implements OnInit, OnDestroy {
  public loading: boolean = true;
  public user: AdminUser;
  public supportedAirports = SupportedAirports;
  public selectedAirport: string;

  public manifests: any[];
  public loadingManifests: boolean = false;
  public gettingManifest: boolean = false;
  public displayedColumns: string[] = ['date', 'id', 'airport', 'shipments', 'actions'];
  public DATE_FORMAT: string = DateFormats.DATE_SHORT;
  public CDN_URL: string = environment.CDN_URL;

  constructor(
    private helpersService: HelpersService,
    private logger: LoggerService,
    private userService: UserService,
    private shipmentsService: ShipmentsService,
    private snackBar: MatSnackBar,
    private drawer: MtxDrawer,
    private router: Router
  ) {}

  ngOnInit() {
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_MANIFESTS])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            // sort airports ascending by name
            this.supportedAirports.sort((a, b) => (a.name < b.name ? -1 : 1));
            this.loading = false;
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }

  ngOnDestroy() {
    if (this.drawer) {
      this.drawer.dismiss();
    }
    if (this.snackBar) {
      this.snackBar.dismiss();
    }
  }

  public downloadManifest(manifest: any) {
    this.getManifest(manifest, true);
  }

  public viewManifest(manifest: any) {
    this.getManifest(manifest, false);
  }

  public getManifests() {
    if (!this.loadingManifests) {
      this.loadingManifests = true;
      this.manifests = [];
      const group = `AC_${this.selectedAirport}`;
      this.shipmentsService.getManifests({ group }).subscribe(
        (manifests) => {
          this.manifests = manifests;
          this.logger.log(`GET manifests for ${this.selectedAirport}`, manifests);
          this.loadingManifests = false;
        },
        (err) => {
          this.logger.error(`GET manifests for ${this.selectedAirport} error`, err);
          this.loadingManifests = false;
        }
      );
    }
  }

  public getManifest(manifest: any, onlyDownload: boolean = true) {
    if (!this.gettingManifest) {
      this.gettingManifest = true;
      const manifestNumber = manifest.manifestNumber;
      const query = {
        provider: ShippingProviders.CANADA_POST,
        type: 'manifests',
        id: manifest.id,
        manifestNumber: manifestNumber.indexOf(',') > 0 ? manifestNumber.split(',')[0] : manifestNumber,
      };
      return this.shipmentsService.getDocuments(query).subscribe(
        (manifestRes: any) => {
          this.logger.log('GET Manifest', manifestRes);

          if (manifestRes.errors.length === 0) {
            let mimeType = 'application/pdf';
            const downloadFileName = `${manifest.group || query.manifestNumber}-manifest.pdf`;
            if (onlyDownload) {
              const blob = this.helpersService.b64toBlob(manifestRes.data, mimeType);
              FileSaver.saveAs(blob, downloadFileName);
            } else {
              this.drawer.open(ViewDocumentComponent, {
                width: this.helpersService.getDrawerWidth(),
                disableClose: true,
                closeOnNavigation: false,
                data: {
                  document: manifestRes.data,
                  downloadFileName,
                  previewTitle: 'View Manifest',
                },
              });
            }
            this.gettingManifest = false;
          } else {
            this.snackBar.open('Could not get shipment document', '', {
              panelClass: 'error',
            });
            this.logger.error('GET shipment document error', {
              errors: manifestRes.errors,
            });
            this.gettingManifest = false;
          }
        },
        (err) => {
          this.snackBar.open('Could not retrieve manifest', '', {
            panelClass: 'error',
          });
          this.logger.error('GET manifest error', err);
          this.gettingManifest = false;
        }
      );
    }
  }
}
