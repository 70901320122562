import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { CurrencyPipe, formatCurrency } from '@angular/common';
import { first } from 'rxjs';
import { DateTime } from 'luxon';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import {
  AdminScopes,
  AdminUser,
  UserService,
} from '@app/shared/services/user.service';
import { Animations, BillingTypes, BillingTypesVerbose, EDI_INVOICE_MODAL_TYPE, PanelWidths, ShipmentChargeStatus, ShipmentStatuses, ShippingProviderNames, ShippingProviders, TrackingTypes } from '../../../../shared/constants';
import { AdjustmentsService } from '../../../../shared/services/adjustments.service';
import { ProvidersService } from '../../../../shared/services/providers.service';
import { environment } from '../../../../../environments/environment';
import { ShipmentsService } from '../../../../shared/services/shipments.service';
import { ModalCreateReturnLabelComponent } from '../shipments/components/modal-create-return-label/modal-create-return-label.component';
import { ModalConfirmationComponent } from '../../../../shared/components/modal-confirmation/modal-confirmation.component';
import { CanadaPostReceiptComponent } from '../../components/canada-post-receipt/canada-post-receipt.component';
import { EdiInvoiceComponent } from '../../components/edi-invoice/edi-invoice.component';
import { AdminChargeDetailsComponent } from '../../components/admin-charge-details/admin-charge-details.component';
import { BillingInvoicesService } from '../../../../shared/services/billing-invoices.services';
import { InsuranceService } from '../../../../shared/services/insurance.service';
import { TrackingComponent } from '../../components/tracking/tracking.component';
import { ModalsAssignOtherToChargeComponent } from './modals/assign-other-to-charge/modals-assign-other-to-charge.component';
import { ModalsBatchAdjustmentsComponent } from './modals/batch-adjustments/modals-batch-adjustments.component';

@Component({
  selector: 'app-reconciliation',
  templateUrl: './reconciliation.component.html',
  animations: [Animations.ExpandAnimation]
})
export class ReconciliationComponent implements OnInit {
  public loading: boolean = true;
  public user: AdminUser;

  public defaultTimezone = 'America/Toronto';
  public progress: number = 0;

  private regexOther = new RegExp('Other');
  private defaultStatus: {
    id: string;
    value: ShipmentChargeStatus;
    text: string;
  } = {
    id: ShipmentChargeStatus.ADJUSTMENT_PENDING,
    value: ShipmentChargeStatus.ADJUSTMENT_PENDING,
    text: 'Pending / Declined',
  };

  public daterange = {
    start: DateTime.now().minus({ months: 3 }).startOf('day').toJSDate(),
    end: DateTime.now().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toJSDate(),
  };

  public range = new FormGroup({
    start: new FormControl<Date | null>(this.daterange.start),
    end: new FormControl<Date | null>(this.daterange.end),
  });
  private orderByValue = 'createdAt';
  public sortDescending: boolean = true;
  public approveAllInProgress: boolean = false;
  public trackingNumber: string = '';
  public companyId: string = '';

  public selectedFilters = {
    fromDate: this.daterange.start,
    toDate: this.daterange.end,
    chargeStatus: this.defaultStatus.value,
    trackingNumber: this.trackingNumber || '',
    provider: 'all',
  };

  public JobPollInterval: number | null;
  public defaultSearchText: string = `Status: ${this.defaultStatus.text}`;
  public filtersText: string = this.defaultSearchText;

  public statusItems = [
    {
      id: ShipmentChargeStatus.ADJUSTMENT_PENDING,
      value: ShipmentChargeStatus.ADJUSTMENT_PENDING,
      text: 'Pending / Declined',
    },
    {
      id: ShipmentChargeStatus.ADJUSTMENT_APPROVED,
      value: ShipmentChargeStatus.ADJUSTMENT_APPROVED,
      text: 'Approved',
    },
    {
      id: ShipmentChargeStatus.ADJUSTMENT_DECLINED,
      value: ShipmentChargeStatus.ADJUSTMENT_DECLINED,
      text: 'Declined',
    },
    {
      id: ShipmentChargeStatus.ADJUSTMENT_IGNORED,
      value: ShipmentChargeStatus.ADJUSTMENT_IGNORED,
      text: 'Ignored',
    },
    {
      id: ShipmentChargeStatus.UNKNOWN_REASON,
      value: ShipmentChargeStatus.UNKNOWN_REASON,
      text: 'Unknown',
    },
    {
      id: ShipmentChargeStatus.DO_NOT_BILL_ADJUSTMENTS,
      value: ShipmentChargeStatus.DO_NOT_BILL_ADJUSTMENTS,
      text: 'Not Bill Adjustments',
    },
    {
      id: 'any',
      value: 'any',
      text: 'Any',
    },
  ];

  public providerItems = [
    { id: 'all', value: 'all', text: 'All' },
    {
      id: ShippingProviders.PUROLATOR,
      value: ShippingProviders.PUROLATOR,
      text: ShippingProviderNames.PUROLATOR,
    },
    { id: ShippingProviders.DHL, value: ShippingProviders.DHL, text: ShippingProviderNames.DHL },
    { id: ShippingProviders.GLS, value: ShippingProviders.GLS, text: ShippingProviderNames.GLS },
    {
      id: ShippingProviders.NATIONEX,
      value: ShippingProviders.NATIONEX,
      text: ShippingProviderNames.NATIONEX,
    },
    {
      id: ShippingProviders.CANPAR,
      value: ShippingProviders.CANPAR,
      text: ShippingProviderNames.CANPAR,
    },
    {
      id: ShippingProviders.FEDEX,
      value: ShippingProviders.FEDEX,
      text: ShippingProviderNames.FEDEX,
    },
    { id: ShippingProviders.RIVO, value: ShippingProviders.RIVO, text: ShippingProviderNames.AIR_CANADA },
    {
      id: ShippingProviders.CANADA_POST,
      value: ShippingProviders.CANADA_POST,
      text: ShippingProviderNames.CANADA_POST,
    },
    {
      id: ShippingProviders.CHASSEURS_COURRIER,
      value: ShippingProviders.CHASSEURS_COURRIER,
      text: ShippingProviderNames.CHASSEURS_COURRIER,
    },
    {
      id: ShippingProviders.UNIUNI,
      value: ShippingProviders.UNIUNI,
      text: ShippingProviderNames.UNIUNI,
    },
    {
      id: ShippingProviders.FLASHBOX,
      value: ShippingProviders.FLASHBOX,
      text: ShippingProviderNames.FLASHBOX,
    },
    { id: ShippingProviders.UPS, value: ShippingProviders.UPS, text: ShippingProviderNames.UPS },
    { id: ShippingProviders.ICS_COURIER, value: ShippingProviders.ICS_COURIER, text: ShippingProviderNames.ICS_COURIER },
    { id: ShippingProviders.LIVRAPIDE, value: ShippingProviders.LIVRAPIDE, text: ShippingProviderNames.LIVRAPIDE },
    { id: ShippingProviders.CANADA_POST_3PL, value: ShippingProviders.CANADA_POST_3PL, text: ShippingProviderNames.CANADA_POST_3PL },
    { id: ShippingProviders.DELIVRO, value: ShippingProviders.DELIVRO, text: ShippingProviderNames.DELIVRO },
  ];

  public gettingLabel: boolean = false;
  public searchForm: FormGroup | undefined;
  public companyAdjustments: any = [];
  public companyAdjustmentsCount = 0;
  public paginatedAdjustments: any[] = [];
  public currentPage: number = 1;
  public itemsPerPage: number = 15;

  public displayedColumns: string[] = [
    'select',
    'name',
    'email',
    'shipments',
    'totalAdjustment',
    'action'
  ];

  public displayedNestedColumns: string[] = [
    'select',
    'date',
    'provider',
    'paymentType',
    'price',
    'adjustment',
    'adjustmentDetails',
    'adjustmentAction',
  ]

  public step: number = 0;
  public expandedElement = null;
  public expandedShipmentElement = null;

  // shipments
  public canEdit: boolean = false;
  public canViewShipmentDetails: boolean = false;
  public canViewManifests: boolean = false;
  public canCreateReturns: boolean = false;
  // TODO: Revisit if we support return label for ACC-multi-courier Intl
  public isDomesticAccShipment: boolean = false;
  public noReferences = true;
  public additionalDetails: any = {
    reference1: '',
    reference2: '',
    additionalNote: '',
  };
  public CDN_URL: string = environment.CDN_URL;
  public rates;
  public shipmentStatuses = ShipmentStatuses;
  public shippingProviders = ShippingProviders;
  public gettingCertificate: boolean = false;
  public showingTracking: boolean = false;
  private currencyPipe = new CurrencyPipe('en');


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private drawer: MtxDrawer,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private logger: LoggerService,
    private helpersService: HelpersService,
    private providersService: ProvidersService,
    private adjustmentsService: AdjustmentsService,
    private shipmentsService: ShipmentsService,
    private billingInvoicesService: BillingInvoicesService,
    private insuranceService: InsuranceService,
  ) {}
  ngOnInit() {
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_RECONCILIATION])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.canEdit = this.userService.hasAuthScope(this.user, [
              AdminScopes.MODIFY_RECONCILIATION,
            ]);
            this.canViewManifests = this.userService.hasAuthScope(this.user, [
              AdminScopes.VIEW_MANIFESTS,
            ]);
            this.canCreateReturns = this.userService.hasAuthScope(this.user, [
              AdminScopes.MODIFY_SHIPMENTS_RETURN_LABEL,
            ]);
            this.canViewShipmentDetails = this.userService.hasAuthScope(
              this.user,
              [AdminScopes.VIEW_SHIPMENTS_DETAILS]
            );
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );

      this.route.paramMap.subscribe((params: ParamMap) => {
        this.companyId = params.get('companyId');
      })

      this.route.queryParams.subscribe(params => {
        this.currentPage = parseInt(params['page'], 10) || 1;
      });

      this.startPolling();
      this.createForm();
      this.getAdjustments();
  }

  public approveAll(): void {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: '600px',
      data: {
        title: `Confirmation`,
        content: 'Are you sure you want to approve all adjustments?',
        confirmBtnLabel: 'Approve all',
        btnColor: 'primary',
      },
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.approveAllInProgress = true;
        this.adjustmentsService.triggerApproveAllRecon().subscribe(
          (response) => {
            this.progress = 1;
            this.startPolling(() => {
              this.snackBar.open('Approve all adjustments job finished successfully', '', {
                panelClass: 'success',
              });
              this.loading = true;
              this.updateFiltersText();
              this.getAdjustments();
            });
            this.logger.log('Reconciliation, approve-all successful', response);
          },
          (error) => {
            this.snackBar.open('Approve all has already been triggered...', '', {
              panelClass: 'error',
            });
            this.logger.error('Reconciliation, approve-all error', error);
            this.restartPolling();
          }
        );
      }
    });
  }

  public setStep(index: number) {
    this.step = index;
  }

  public toggleAdjustmentDetails(adjustmentRow: any) {
    const sameRowClicked = this.expandedElement === adjustmentRow;
    const isAdjustmentDetailsExpanded = !!this.expandedElement;

    if (this.step === 0) {
      this.setStep(1);
      this.expandedElement = adjustmentRow;
      this.expandedShipmentElement = null;
    } else if (this.step === 1) {
      if (sameRowClicked) {
        this.expandedElement = null
        this.setStep(this.step--)
      } else {
        this.expandedElement = adjustmentRow;
        this.expandedShipmentElement = null;
      }
    } else {
        this.setStep(0)
        this.expandedElement = null;
        this.expandedShipmentElement = null;
    }
  }

  public approveRecon(companyAdjustment, shipment): void {
    const companyId = companyAdjustment.account.companyId;
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: '600px',
      data: {
        title: 'Approve Adjustment',
        content: `Approve single adjustment of ${formatCurrency(this.getAdjustmentValue(shipment), 'en', '$', 'CAD')}` +
        ` for ${this.getAccountName(companyAdjustment)}?`,
        btnColor: 'primary',
        confirmBtnLabel: 'Approve',
      },
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.loading = true;
        this.adjustmentsService
          .approveAdjustment({
            companyId: companyId,
            shipmentIds: [shipment.id],
          })
          .subscribe(
            (res: Response) => {
              this.getAdjustments();
              this.snackBar.open('Adjustment approved successfully', '', {
                panelClass: 'success',
              });
              this.logger.log(
                `Reconciliation, Approve adjustment shipment with id ${shipment.id}`,
                res
              );
            },
            (err: string) => {
              this.snackBar.open('Failed to approve adjustment: ', '', {
                panelClass: 'error',
              });
              this.logger.error('Reconciliation, Approve adjustment error', err);
              this.getAdjustments();
            }
          );
      }
      });
  }

  public ignoreRecon(companyAdjustment, shipment): void {
    const companyId = companyAdjustment.account.companyId;

    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: '600px',
      data: {
        title: 'Ignore Adjustment',
        content: `Ignore adjustment of ${formatCurrency(this.getAdjustmentValue(shipment), 'en', '$', 'CAD')} for ${
          shipment.senderInfo.name
        }?`,
        btnColor: 'warn',
        confirmBtnLabel: 'Ignore',
      },
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.loading = true;
        this.adjustmentsService
          .ignoreAdjustment({
            companyId: companyId,
            shipmentIds: [shipment.id],
          })
          .subscribe(
            (res: Response) => {
              this.getAdjustments();
              this.snackBar.open('Adjustment ignored successfully', '', {
                panelClass: 'success',
              });
              this.logger.log(
                `Reconciliation, Ignore adjustment shipment with id ${shipment.id}`,
                res
              );
            },
            (err: Error) => {
              this.snackBar.open('Failed to ignore adjustment', '', {
                panelClass: 'error',
              });
              this.logger.error('Reconciliation, Ignore adjustment error', err);
            }
          );
      }
    });
  }

  public deleteOtherAndRecalculateAdjustment(companyAdjustment, shipment): void {
    const companyId = companyAdjustment.account.companyId;
    let otherReasons: Array<string>;

    for (const adjustmentCharge of shipment.rates.adjustmentCharges) {
      otherReasons = adjustmentCharge.reasons.filter((reason) => this.regexOther.test(reason));
    }

    if (!otherReasons || otherReasons.length === 0 || otherReasons.length > 1) {
      this.snackBar.open('Adjustment should have exactly one "Other Surcharge"', '', {
        panelClass: 'error',
      });
      return;
    }

    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: '600px',
      data: {
        title: 'Confirmation',
        content: `<span>Are you sure you want to remove <b>${otherReasons[0]}</b> from this adjustment?<span>`,
        btnColor: 'primary',
        confirmBtnLabel: 'Confirm',
      },
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.loading = true;
        this.adjustmentsService
          .deleteOtherAndRecalculateAdjustment({
            companyId: companyId,
            shipmentId: shipment.id,
          })
          .subscribe(
            (res: Response) => {
              this.getAdjustments();
              this.snackBar.open('"Other" surcharge removed from adjustment', '', {
                panelClass: 'success',
              });
              this.logger.log(
                `Reconciliation,, Remove "Other" surcharge for shipment with id ${shipment.id}`,
                res
              );
            },
            (err: string) => {
              this.snackBar.open('Failed to remove "Other" surcharge from adjustment: ' + err, '', {
                panelClass: 'error',
              });
              this.logger.error('Reconciliation,, Remove "Other" surcharge error', err);
              this.getAdjustments();
            }
          );
      }
    });
  }

  public reassignOther(companyAdjustment, shipment): void {
    const companyId = companyAdjustment.account.companyId;
    let otherReasons: string[];

    for (const adjustmentCharge of shipment.rates.adjustmentCharges) {
      otherReasons = adjustmentCharge.reasons.filter((reason) => this.regexOther.test(reason));
    }

    if (!otherReasons || otherReasons.length === 0 || otherReasons.length > 1) {
      this.snackBar.open('Adjustment should have exactly one "Other Surcharge"', '', {
        panelClass: 'error',
      });
      return;
    }

    if (Number(otherReasons[0].split('$')[1]) < 0) {
      this.snackBar.open('"Other Surcharge" should be a positive value', '', {
        panelClass: 'error',
      });
      return;
    }

    const dialogRef = this.dialog.open(ModalsAssignOtherToChargeComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((charge) => {
      if (charge && charge.chargeCode && charge.chargeName) {
        const { chargeCode, chargeName } = charge;
        this.loading = true;
        this.adjustmentsService
          .assignOtherInAdjustment({
            companyId: companyId,
            shipmentId: shipment.id,
            chargeCode,
            chargeName,
          })
          .subscribe(
            (res: Response) => {
              this.getAdjustments();
              this.snackBar.open(`"Other" surcharge assigned to ${charge.chargeName}`, '', {
                panelClass: 'success',
              });
              this.logger.log(
                `Reconciliation, Assign "Other" surcharge to ${charge.chargeName} for shipment with id ${shipment.id}`,
                res
              );
            },
            (err: string) => {
              this.snackBar.open(`Failed to assign "Other" surcharge to ${charge.chargeName}` + err, '', {
                panelClass: 'error',
              });
              this.logger.error(
                `Reconciliation, Failed to assign "Other" surcharge to ${charge.chargeName}`,
                err
              );
              this.getAdjustments();
            }
          );
      }
    });
  }

  public toggleShipmentDetails(shipmentRow: any) {
    const isShipmentDetailsExpanded = !!this.expandedShipmentElement;
    const clickOnSameRow = this.expandedShipmentElement === shipmentRow;

    if (this.step === 1 && (isShipmentDetailsExpanded && clickOnSameRow)) {
        this.expandedShipmentElement = null;
    } else if (this.step === 1 && ((isShipmentDetailsExpanded && !clickOnSameRow) || !isShipmentDetailsExpanded)) {
      this.expandedShipmentElement = shipmentRow;
      this.setStep(this.step++)
    } else if (this.step === 2 && (isShipmentDetailsExpanded && clickOnSameRow)) {
      this.expandedShipmentElement = null;
      this.setStep(this.step--)
    } else if (this.step === 2 && ((isShipmentDetailsExpanded && !clickOnSameRow) || !isShipmentDetailsExpanded)) {
      this.expandedShipmentElement = shipmentRow;
    }
  }

  public reset() {
    this.createForm()
    this.getAdjustments();
  }

  public search(reset: boolean = true) {
    this.loading = true;
    this.currentPage = reset ? 1 : this.currentPage;
    const queryData = this.generateQueryData()
    this.getAdjustments(queryData);
  }

  public copyText(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.width = '0';
    textarea.style.height = '0';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.snackBar.open(
      'Tracking number copied to clipboard',
      'Dismiss',
      { panelClass: 'success', duration: 2000 }
    );
  }

  public approveBatchRecon(companyAdjustment): void {
    const companyId = companyAdjustment.account.companyId;

    const returnDialogRef = this.dialog.open(
      ModalsBatchAdjustmentsComponent,
      {
        width: '1000px',
        data: {
          title: 'Approve Adjustments',
          template: 'primary',
          action: 'Approve',
          companyAdjustment: this.setupCompanyAdjustmentForBatch(companyAdjustment),
        },
      }
    );
    returnDialogRef.afterClosed().subscribe((shipmentIds) => {
      if (shipmentIds && shipmentIds?.length > 0) {
        this.loading = true;
        this.adjustmentsService
          .approveAdjustment({
            companyId: companyId,
            shipmentIds: shipmentIds,
          })
          .subscribe(
            (res: Response) => {
              this.getAdjustments();
              this.snackBar.open(`${shipmentIds.length} Adjustments approved successfully`, '', {
                panelClass: 'success',
              });
              this.logger.log(
                `Reconciliation,, Approved batch adjustments for company ${companyAdjustment.account.companyId}`,
                res
              );
            },
            (err: string) => {
              this.snackBar.open('Failed to approve adjustments: ', '', {
                panelClass: 'error',
              });
              this.logger.error('Reconciliation,, Approve adjustment error', err);
              this.getAdjustments();
            }
          );
      }
    });
  }

  public ignoreBatchRecon(companyAdjustment): void {
    const companyId = companyAdjustment.account.companyId;

    const returnDialogRef = this.dialog.open(
      ModalsBatchAdjustmentsComponent,
      {
        width: '1000px',
        data: {
          title: 'Ignore Adjustments',
          template: 'warn',
          action: 'Ignore',
          companyAdjustment: this.setupCompanyAdjustmentForBatch(companyAdjustment),
        },
      }
    );
    returnDialogRef.afterClosed().subscribe((shipmentIds) => {
      if (shipmentIds && shipmentIds?.length > 0) {
        this.loading = true;
        this.adjustmentsService
          .ignoreAdjustment({
            companyId: companyId,
            shipmentIds: shipmentIds,
          })
          .subscribe(
            (res: Response) => {
              this.getAdjustments();
              this.snackBar.open(`${shipmentIds.length} Adjustments ignored successfully`, '', {
                panelClass: 'success',
              });
              this.logger.log(
                `Reconciliation,, Ignored batch adjustments for company ${companyAdjustment.account.companyId}`,
                res
              );
            },
            (err: Error) => {
              this.snackBar.open('Failed to ignore adjustments', '', {
                panelClass: 'error',
              });
              this.logger.error('Reconciliation,, Ignore adjustment error', err);
            }
          );
      }
    });
  }

  public getProviderCode(service) {
    const serviceProvider = service.provider;
    let serviceName = '';
    if (service.name) {
      serviceName = service.name.toLowerCase();
    }
    if (
      serviceName &&
      serviceName.includes('fedex') &&
      serviceName.includes('ground')
    ) {
      return 'fedex-ground';
    } else if (
      serviceName &&
      serviceName.includes('fedex') &&
      (serviceName.includes('express') ||
        serviceName.includes('overnight') ||
        serviceName.includes('economy') ||
        serviceName.includes('2day'))
    ) {
      return 'fedex-express';
      // TODO: AC - Revisit when we want to replace completely Rivo with AirCanada
    } else if (serviceProvider === ShippingProviders.RIVO) {
      if (
        (service.code || '').toUpperCase().includes('AC') ||
        (service.name || '').toUpperCase().includes('MULTI')
      ) {
        return this.providersService.getAirCanadaImageByServiceName(
          serviceName
        );
      } else {
        return serviceProvider ? serviceProvider : null;
      }
    } else {
      return serviceProvider ? serviceProvider : null;
    }
  }

  public getProviderName(provider: ShippingProviders, service?: any): string {
    return this.helpersService.convertShipmentProviderToString(provider, service);
  }

  public getPaymentType(billingType: BillingTypes): BillingTypesVerbose {
    switch (billingType) {
      case BillingTypes.MACHOOL_ACCOUNT:
        return BillingTypesVerbose.MACHOOL_ACCOUNT;
      default:
        return BillingTypesVerbose.CREDIT_CARD;
    }
  }

  public getAdjustmentValue(shipment: any): number {
    return shipment.rates.adjustmentCharges && shipment.rates.adjustmentCharges.length > 0
      ? shipment.rates.adjustmentCharges.map((charge) => charge.amount).reduce((a, b) => a + b)
      : null;
  }

  public getAdjustmentReasons(shipment: any): string {
    let reasons = '';

    shipment.rates.adjustmentCharges.forEach((adjustmentCharge, index, array) => {
      const finalIndex = array.length - 1;
      if (Array.isArray(adjustmentCharge.reasons)) {
        reasons += `${adjustmentCharge.reasons.join(', ')}`;
      } else {
        reasons += adjustmentCharge.reasons.toString();
      }
      if (finalIndex !== index) reasons += ' - ';
    });

    return reasons;
  }

  public getMissingOrUnknownReasonPopoverMessage(shipment: any): string {
    const reasons = this.getAdjustmentReasons(shipment);

    if (_.isEmpty(reasons)) {
      return 'No specified reason found';
    } else {
      return 'Unknown adjustment charge: Other';
    }
  }

  public getAccountName(company): string {
    if (company.account.companyName) {
      return `${company.account.companyName} / ${company.account.firstName} ${company.account.lastName}`.trim();
    } else {
      return `${company.account.firstName} ${company.account.lastName}`.trim();
    }
  }

  private startPolling(callback?: Function): void {
    if (this.JobPollInterval) return;

    this.JobPollInterval = window.setInterval(() => {
      this.adjustmentsService.getApproveAllJobStatus().subscribe(
        (progressResponse) => {
          if (progressResponse.progress > 0) {
            this.approveAllInProgress = true;
          }
          this.logger.log('Reconciliation, approve-all polling successful', progressResponse);
          this.progress = progressResponse.progress;

          if (!progressResponse.isJobActive || progressResponse.progress === 100) {
            clearInterval(this.JobPollInterval);
            this.JobPollInterval = null;
            this.approveAllInProgress = false;

            if (callback) {
              callback();
            }
          }
        },
        (error) => {
          this.logger.error('Reconciliation, approve-all polling error', error);
          this.restartPolling();
        }
      );
    }, 2000);
  }

  private restartPolling(): void {
    clearInterval(this.JobPollInterval);
    this.JobPollInterval = null;
    this.startPolling();
  }

  private getAdjustments(withQuery = false) {
    this.loading = true;

    const query = withQuery || {
      start_date: DateTime.fromJSDate(this.range.value.start).toUTC(),
      end_date: DateTime.fromJSDate(this.range.value.end).toUTC(),
      tracking_number: this.selectedFilters.trackingNumber || this.trackingNumber || '',
      charge_status: this.selectedFilters.chargeStatus || this.defaultStatus.value,
      provider: this.selectedFilters.provider || 'all',
      items_per_page: this.itemsPerPage,
      page: this.currentPage,
      order_by_value: this.orderByValue,
      order_by_direction: this.sortDescending ? 'DESC' : 'ASC',
      company: this.companyId || '',
      include_admin_charge_details: true,
      is_admin_call: true,
    };

    this.companyAdjustments = [];
    this.paginatedAdjustments = [];
    this.companyAdjustmentsCount = 0;

    this.adjustmentsService.findAllAdjustments(query).subscribe(
      (res: any) => {
        this.logger.log('Adjustments, GET All Adjustments', res);
        this.companyAdjustments = res.companyAdjustments;
        this.companyAdjustmentsCount = res.companyAdjustmentsCount;
        this.setupCompanyAdjustments(this.companyAdjustments);
        this.paginatedAdjustments = this.companyAdjustments;
        this.loading = false;
      },
      (err: Error) => {
        this.logger.error('Adjustments, GET All Adjustments error', err);
        this.loading = false;
        this.companyAdjustmentsCount = 0;
        this.companyAdjustments = [];
      }
    );
  }

  private setupCompanyAdjustments(companyAdjustments): void {
    this.companyAdjustments.map((companyAdjustment: any) => {
      if (companyAdjustment) {
        companyAdjustment.name = this.getAccountName(companyAdjustment);
      }
      if (companyAdjustment.shipments && companyAdjustment.shipments.length > 0) {
        companyAdjustment.shipments.map((shipment: any) => {
          // merged shipments
          shipment.isMergedShipment = this.isMergedShipment(shipment);
          shipment.mergedShipmentPopover = shipment.isMergedShipment ? this.getMergedShipmentsChildren(shipment) : '';
          // shipment with unknown tracking numbers assigned to it
          shipment.assignedUnknownShipmentPopover = shipment.hasAssignedUnknownShipment
            ? this.getAssignedUnknownShipments(shipment)
            : '';
          shipment.adjustmentReasons = this.getAdjustmentReasons(shipment);
          shipment.providerImage = shipment.isMultiLeg
          ? this.getProviderImage(
              shipment.shipmentLegs[0]?.provider,
              shipment.service
            )
          : this.getProviderImage(shipment.provider, shipment.service);
          shipment.providerName = shipment.isMultiLeg
          ? this.helpersService.convertShipmentProviderToString(
              shipment.shipmentLegs[0]?.provider
            )
          : this.helpersService.convertShipmentProviderToString(shipment.provider);
          shipment.providerCode = this.getProviderCode(shipment.service);
          shipment.statusVerbose =
            this.helpersService.convertLegacyShipmentStatusEnumToString(
              shipment.status
            );
          shipment.trackingStatusVerbose = shipment.trackingStatus
            ? this.helpersService.convertLegacyShipmentStatusEnumToString(
                shipment.trackingStatus
              )
            : 'Not set';
          shipment.providerLogoSrc = this.getProviderLogoSrc(
            shipment.providerCode
          );

          shipment.hasReturnLabel =
            this.shipmentsService.isPrintableReturnLabelAvailable(shipment);
          shipment.hasThirdPartyInsurance =
            shipment.rates.thirdPartyInsurance &&
            !_.isEmpty(shipment.rates.thirdPartyInsurance);
          shipment.isCPNC =
            shipment.provider === ShippingProviders.CANADA_POST &&
            _.includes(shipment.group, 'NC_');
          shipment.senderInfo.fullPhone = this.getPhoneNumber(
            shipment.senderInfo
          );
          shipment.senderInfo.formattedCostCenter = _.get(
            shipment.senderInfo.costCenter,
            'name',
            shipment.senderInfo.costCenter
          );
          shipment.recipientInfo.buzzer = _.get(
            shipment,
            'recipientInfo.address.additionalInfo'
          );
          shipment.recipientInfo.fullPhone = this.getPhoneNumber(
            shipment.recipientInfo
          );
        this.isDomesticAccShipment =
          shipment.provider === ShippingProviders.RIVO &&
          (shipment.recipientInfo.address.country || '').toUpperCase().includes('CA');

        shipment.updatedRates = [];

        this.rates = this.shipmentsService.rateByProvider(
          shipment.provider,
          shipment.service.name,
          shipment.rates || {}
        );

        if (this.rates) {
          shipment.hasOptions = this.hasOptions(this.rates);
          this.rates.forEach((rate) => {
            if (!_.get(rate, 'amount') && _.get(rate, 'value')) {
              rate.amount = rate.value;
            }
            if (rate.list && rate.list.length > 0) {
              rate.list.forEach((listItem) => {
                if (!_.get(listItem, 'amount') && _.get(listItem, 'value')) {
                  listItem.amount = listItem.value;
                }
              });
            }
          });

          this.filterRates(shipment);
          if (
            shipment.rates.adjustmentCharges &&
            shipment.rates.adjustmentCharges.length > 0
          ) {
            const metadata = shipment.rates.adjustmentCharges.flatMap(
              (adjustmentCharge) => adjustmentCharge.metadata
            );
            const metadataForMatchingTrackingNumber = metadata.find(
              (m) => m.trackingNumber === shipment.rates.trackingNumber
            );

            if (
              metadataForMatchingTrackingNumber &&
              metadataForMatchingTrackingNumber.adjustedWeight > 0
            ) {
              shipment.adjustedWeight =
                metadataForMatchingTrackingNumber.adjustedWeight;
              shipment.adjustedUnits =
                metadataForMatchingTrackingNumber.adjustedUnits;
              shipment.hasAdjustment = true;
            }
          }
        }

        shipment.updatedRates.push(...this.rates);

        this.additionalDetails = {
          reference1: shipment.recipientInfo.reference1,
          reference2: shipment.recipientInfo.reference2,
          additionalNote: shipment.recipientInfo.additionalNote,
        };

        this.noReferences =
          !this.additionalDetails.reference1 &&
          !this.additionalDetails.reference2 &&
          !this.additionalDetails.additionalNote;
        })
      }
    });
  }

  private updateFiltersText(isClearing: boolean = false) {
    if (isClearing) {
      this.filtersText = this.defaultSearchText;
    } else {
      let updatedText = '';
      const fromDate = DateTime.fromJSDate(this.selectedFilters.fromDate).toISO()
      const toDate = DateTime.fromJSDate(this.selectedFilters.toDate).toISO();

      // Date Range
      updatedText += `${fromDate} - ${toDate}`;

      // Charge Status
      if (!_.isEmpty(this.selectedFilters.chargeStatus)) {
        const orderStatus = this.statusItems.find((item) => item.id === this.selectedFilters.chargeStatus);

        if (orderStatus) {
          updatedText += !_.isEmpty(updatedText) ? ', ' : '';
          updatedText += `Status: ${orderStatus.text}`;
        }
      }

      // Tracking Number
      if (!_.isEmpty(this.selectedFilters.trackingNumber)) {
        updatedText += !_.isEmpty(updatedText) ? ', ' : '';
        updatedText += `Order: ${this.selectedFilters.trackingNumber}`;
      }

      this.filtersText = !_.isEmpty(updatedText) ? updatedText : this.defaultSearchText;
    }
  }

  private getAssignedUnknownShipments(shipment) {
    const shipmentWithAssignedUnknown = shipment.hasAssignedUnknownShipment;
    return shipmentWithAssignedUnknown
      .metadata.filter((item) => item.assignedTrackingNumber)
      .filter((item) => item.assignedTrackingNumber !== shipment.tracking)
      .map((item) => ` ${item.assignedTrackingNumber}`);
  }

  private setupCompanyAdjustmentForBatch(companyAdjustment) {
    companyAdjustment.displayName = this.getAccountName(companyAdjustment);

    for (const shipment of companyAdjustment.shipments) {
      shipment.adjustmentReasons = this.getAdjustmentReasons(shipment);
      shipment.adjustmentValue = this.getAdjustmentValue(shipment);
      shipment.provider = this.getProviderName(shipment.provider);
      shipment.selected = true;
    }

    return companyAdjustment;
  }

  private isMergedShipment(shipment) {
    return (
      shipment.rates.adjustmentCharges &&
      shipment.rates.adjustmentCharges.length > 0 &&
      shipment.rates.adjustmentCharges.find((charge) => {
        return (
          charge.metadata &&
          charge.metadata.length > 1 &&
          charge.metadata.filter((item) => item.trackingNumber).length > 1
        );
      })
    );
  }

  private getMergedShipmentsChildren(shipment) {
    const chargeWithMergedShipments = this.isMergedShipment(shipment);

    if (chargeWithMergedShipments) {
      return chargeWithMergedShipments.metadata
        .filter((item) => item.trackingNumber)
        .filter((item) => item.trackingNumber !== shipment.tracking)
        .map((item) => ` ${item.trackingNumber}`);
    } else {
      return '';
    }
  }

  private createForm() {
    this.searchForm = this.formBuilder.group({
      chargeStatus: [this.defaultStatus.value, []],
      trackingNumber: [this.trackingNumber || ''],
      provider: ['all'],
      startDate: [undefined],
      endDate: [undefined],
    });
  }

  // SHIPMENTS
  public getDocuments(shipment: any, type: string) {
    if (!this.gettingLabel) {
      this.gettingLabel = true;
      const shipmentProvider =
        shipment.isMultiLeg && type === 'manifests' && shipment.manifest
          ? shipment.shipmentLegs[0].provider
          : shipment.provider;
      const query = {
        provider: shipmentProvider,
        type: type,
        id: shipment.shipment,
        uuid: shipment.orderId,
      };

      if (type === 'manifests' && shipment.manifest) {
        query.id = shipment.manifest.id;
        _.extend(query, { manifestNumber: shipment.manifest.manifestNumber });
      }

      if (type === 'cod-return-labels') {
        query.id = shipment.codReturn;
      }

      if (
        type === 'return-labels' &&
        [ShippingProviders.PUROLATOR, ShippingProviders.ICS_COURIER].includes(
          shipment.provider
        )
      ) {
        query.id = shipment.returnTrackings[0];
      }
      this.shipmentsService.getDocuments(query).subscribe(
        (res: any) => {
          this.logger.log('GET Shipment documents', res);

          let mimeType = 'application/pdf';
          let downloadFileName = 'download.pdf';
          const providerName = shipment.provider;
          const trackingNumber = shipment.tracking || '';

          if (type === 'high-value-reports') {
            mimeType = 'text/html';
            downloadFileName = 'download.html';
          }

          if (providerName && trackingNumber) {
            switch (type) {
              case 'labels':
                downloadFileName = `${providerName}-${trackingNumber}-label.pdf`;
                break;
              case 'commercial-invoices':
                downloadFileName = `${providerName}-${trackingNumber}-customs.pdf`;
                break;
              case 'return-labels':
                downloadFileName = `${providerName}-${trackingNumber}-return-label.pdf`;
                break;
              case 'cod-receipts':
                downloadFileName = `${providerName}-${trackingNumber}-cod-receipt.pdf`;
                break;
              case 'manifests':
                downloadFileName = `${shipment.group}-manifest.pdf`;
                break;
              case 'cod-return-labels':
                downloadFileName = `${providerName}-${trackingNumber}-cod-return-label.pdf`;
                break;
            }
          }

          if (res.errors.length === 0) {
            const blob = this.helpersService.b64toBlob(res.data, mimeType);
            FileSaver.saveAs(blob, downloadFileName);
            this.gettingLabel = false;
          } else {
            this.snackBar.open('Could not get shipment document', '', {
              panelClass: 'error',
            });
            this.logger.error('GET shipment document error', {
              errors: res.errors,
            });
            this.gettingLabel = false;
          }
        },
        (err: any) => {
          this.snackBar.open('Could not get shipment document', '', {
            panelClass: 'error',
          });
          this.logger.error('GET shipment document error', err);
          this.gettingLabel = false;
        }
      );
    }
  }

  public openTracking(company: any, shipment: any, type?: string): void {
    if (shipment.provider === ShippingProviders.LIVRAPIDE) {
      window.open(
        `https://plus.shiptrackapp.com/view.aspx?lng=&tracking=${shipment.tracking}`
      );
      return;
    }
    return this.openTrackingModal(company, shipment, type);
  }

  public createReturnLabel(shipment: any) {
    if (this.canCreateReturns && this.isDomesticAccShipment) {
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        width: '600px',
        data: {
          title: 'Create return label',
          content: `Are you sure you want to create a return label?`,
          btnColor: 'primary',
          confirmBtnLabel: 'Create return label',
        },
      });
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          // Swap sender
          const sender = shipment.recipientInfo;
          // If missing email
          if (!sender.email || sender.email === '') {
            sender.email = shipment.companyAndUserInfo.createdBy.email;
          }
          // If missing phone number, fallback with origin sender/company phone
          if (!sender.phone || sender.phone === '') {
            sender.phone =
              shipment.senderInfo.phone ||
              shipment.companyAndUserInfo.company.phone;
          }
          const selectedService =
            _.isObject(shipment.service) && _.get(shipment, 'service.code')
              ? shipment.service
              : shipment.serviceObj;

          // Swap recipient
          const recipient = shipment.senderInfo;

          const createReturnShipmentDto =
            this.shipmentsService.buildShipmentDTO(
              sender,
              recipient,
              shipment.items,
              undefined,
              selectedService
            );

          // Extend parent shipment for return label
          _.extend(createReturnShipmentDto, {
            parentShipment: shipment,
            isCreateReturnShipment: true,
            accountType:
              shipment?.accountType || _.get(selectedService, 'type'),
            isMultiLeg: shipment.provider === ShippingProviders.RIVO,
            companyAndUserInfo: _.get(shipment, 'companyAndUserInfo'),
          });

          const returnDialogRef = this.dialog.open(
            ModalCreateReturnLabelComponent,
            {
              width: '600px',
              data: {
                shipment,
                createReturnShipmentDto: createReturnShipmentDto,
              },
            }
          );
          returnDialogRef.afterClosed().subscribe((successfulResult) => {
            this.getAdjustments()
          });
        }
      });
    }
  }

  public openCanadaPostReceiptModal(shipment) {
    this.drawer.open(CanadaPostReceiptComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { shipment },
    });
  }

  public openEdiInvoiceModal(shipment) {
    this.drawer.open(EdiInvoiceComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { shipment, type: EDI_INVOICE_MODAL_TYPE.EDI_INVOICE_ONLY },
    });
  }

  public openAdminChargeDetailsModal(shipment) {
    this.drawer.open(AdminChargeDetailsComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { shipment },
    });
  }

  public openInvoiceChargeDetailsModal(invoice) {
    this.billingInvoicesService
      .getInvoicePdf({ invoiceNumber: invoice.invoiceNumber })
      .subscribe(
        (res: any) => {
          const mimeType = 'application/pdf';
          let downloadFileName = 'download.pdf';

          if (invoice.trackingNumber) {
            downloadFileName = `machool-billing-statement-${invoice.trackingNumber}.pdf`;
            // invoice by date range
          } else {
            const startDate = DateTime.fromISO(
              invoice.dateRangeStart
            ).toISODate();
            const endDate = DateTime.fromISO(invoice.dateRangeEnd).toISODate();
            downloadFileName = `machool-billing-statement-${startDate}-${endDate}.pdf`;
          }

          if (res.errors.length === 0) {
            const blob = this.helpersService.b64toBlob(res.data, mimeType);
            FileSaver.saveAs(blob, downloadFileName);
            this.gettingLabel = false;
          } else {
            this.snackBar.open('Could not get shipment invoice', '', {
              panelClass: 'error',
            });
            this.logger.error('GET Billing invoices PDF error', {
              errors: res.errors,
            });
            this.gettingLabel = false;
          }
        },
        (err: Error) => {
          this.logger.error('GET Billing invoices PDF', err);
        }
      );
  }

  public getThirdPartyInsuranceCertificate(shipment: any) {
    if (!this.gettingCertificate) {
      this.gettingCertificate = true;
      const params = {
        certificateNumber: shipment.thirdPartyInsurance.certificateNumber,
        securityId: shipment.thirdPartyInsurance.securityId,
      };
      this.insuranceService.getCertificate(params).subscribe(
        (res: any) => {
          const mimeType = 'application/pdf';
          const downloadFileName = `machool-insurance-${shipment.thirdPartyInsurance.certificateNumber}.pdf`;
          const blob = this.helpersService.b64toBlob(res.data, mimeType);
          FileSaver.saveAs(blob, downloadFileName);
          this.gettingCertificate = false;
        },
        (err: Error) => {
          this.gettingCertificate = false;
          this.logger.error('GET insurance certificate error', err);
        }
      );
    }
  }

  public getTaxRatesLabel(rate) {
    if (!rate.name && rate.code && rate.code.length > 0) {
      if (this.isQstOrPstTax(rate.code.toLowerCase())) {
        return 'QST';
      } else {
        return rate.code.toUpperCase();
      }
    } else {
      if (this.isQstOrPstTax(rate.name.toLowerCase())) {
        return 'QST';
      } else {
        return rate.name.toUpperCase();
      }
    }
  }

  public getReceipt(receiptUrl: string) {
    if (!receiptUrl) return;
    window.open(
      receiptUrl,
      'targetWindow',
      `
      toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=700,
      height=900`
    );
  }

  public showSection(rate): boolean {
    // There are some surcharges or options that are included with amount 0,
    // So we as long as we have something in their list we should display them,
    // for other rates we should just make sure the amount is not 0
    if (rate.label === 'surcharges' || rate.label === 'options') {
      return rate.list && rate.list.length > 0;
    } else {
      return (
        rate.amount !== 0 ||
        (rate.amount === 0 && _.get(rate, 'valueIncluded', false))
      );
    }
  }

  public showAmount(rate): boolean {
    return (
      rate.label !== 'options' &&
      rate.label !== 'surcharges' &&
      rate.label !== 'taxes' &&
      rate.label !== 'total' &&
      rate.label !== 'discount' &&
      rate.amount > 0
    );
  }

  public getRateValue(shipment, value) {
    // Hide rate value if this is a shipment being billed to a third party
    if (!_.isEmpty(shipment.service.thirdParty)) {
      return '-';
    } else {
      const returnValue = _.isString(value)
        ? value
        : this.currencyPipe.transform(value, null, 'symbol', '1.2-2');
      return !returnValue || _.isEmpty(returnValue) || _.isNull(returnValue)
        ? 'Included'
        : returnValue;
    }
  }

  public getProviderImage(provider, service?: any): string {
    return this.providersService.getProviderSmallLogo(provider, service);
  }

  public getProviderLogoSrc(providerCode: string): string {
    // TODO: Legacy provider. Remove once old shipment data archived (4266)
    if (providerCode === ShippingProviders.COURANT_PLUS) {
      providerCode = 'courantplus-nationex';
    }
    return `${this.CDN_URL}/shared/images/couriers/${providerCode}.svg`;
  }

  public pageChanged(pageEvent: PageEvent): void {
    this.itemsPerPage = pageEvent.pageSize;
    this.currentPage = pageEvent.pageIndex + 1;

    this.route.queryParams.subscribe(params => {
      this.router.navigate(['/reconciliation'], { queryParams: { page: this.currentPage } });
    });

    this.getAdjustments(this.generateQueryData());
  }

  private generateQueryData(): any {
    const searchValues = (this.searchForm || {}).value;
    const startDate = DateTime.fromJSDate(this.range.value.start)
      .setZone(this.defaultTimezone)
      .startOf('day');
    const endDate = DateTime.fromJSDate(this.range.value.end)
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 });

    return {
      start_date: startDate.toISO(),
      end_date: endDate.toISO(),
      tracking_number: searchValues.trackingNumber || '',
      charge_status: searchValues.chargeStatus,
      provider: searchValues.provider,
      items_per_page: this.itemsPerPage,
      page: this.currentPage,
      order_by_value: this.orderByValue,
      order_by_direction: this.sortDescending ? 'DESC' : 'ASC',
      company: this.companyId || '',
      include_admin_charge_details: true,
      is_admin_call: true,
    };
  }

  private openTrackingModal(company: any, shipment: any, type?: string): void {
    if (!this.showingTracking) {
      this.showingTracking = true;
      let trackingNumber = shipment.tracking;

      if (type === TrackingTypes.RETURN) {
        trackingNumber = _.get(shipment, 'returnTrackings[0]');
      }

      const { account } = company;
      const companyId = account.companyId;

      // Assign companyId to the company object
      const updatedCompany = { ...account, id: companyId };

      const drawerRef = this.drawer.open(TrackingComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: {
          shipment: shipment,
          trackingNumber,
          company: updatedCompany,
        },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        this.showingTracking = false;
      });
    }
  }

  private isQstOrPstTax(taxCode): boolean {
    return taxCode.includes('qst') || taxCode.includes('pst');
  }

  private surchargeCodeOrNameIncludesFuel = (surcharge: any) => {
    const { code, name } = surcharge;

    if (code && _.isString(code) && code.toLowerCase().includes('fuel')) {
      return true;
    }

    if (name && _.isString(name) && name.toLowerCase().includes('fuel')) {
      return true;
    }

    return false;
  };

  private getPhoneNumber(addressInfo): string {
    const phone = _.get(
      addressInfo,
      'phone.phoneNumber',
      _.get(addressInfo, 'phone', '')
    );
    const phoneExtension = _.get(
      addressInfo,
      'phone.extension',
      _.get(addressInfo, 'phoneExtension', '')
    );

    return phoneExtension && phoneExtension.length > 0
      ? `${phone} x${phoneExtension}`
      : phone;
  }

  private hasOptions(rates) {
    let hasOptions = false;

    for (const rate of rates) {
      if (rate.label === 'options' && rate.list && rate.list.length > 0) {
        hasOptions = true;
      }
    }

    return hasOptions;
  }

  private filterRates(shipment) {
    if (!_.isEmpty(shipment.service.thirdParty)) {
      _.remove(this.rates, { label: 'regular price' });
      _.remove(this.rates, { label: 'taxes' });
      _.remove(this.rates, { label: 'total' });
      _.remove(this.rates, { label: 'discount' });

      const surchargeIndex = _.findIndex(this.rates, { label: 'surcharges' });

      if (surchargeIndex) {
        _.remove(
          this.rates[surchargeIndex].list,
          this.surchargeCodeOrNameIncludesFuel
        );
      }
    }

    // Remove null discounts
    _.remove(this.rates, { label: 'discount', amount: null });
  }
}
