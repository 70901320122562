import { Component, Inject, OnInit } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from '@app/shared/services/company.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DynamicPricingDTO, GlobalCourierRO, ProvidersService } from '@app/shared/services/providers.service';
import * as _ from 'lodash';
import { ShippingProviders } from '@app/shared/constants';

@Component({
  selector: 'app-update-dynamic-pricing',
  templateUrl: './update-dynamic-pricing.component.html',
})
export class UpdateDynamicPricingComponent implements OnInit {
  public isEditing: boolean;
  public loading: boolean = true;
  public company: Company;
  public dynamicPricing: DynamicPricingDTO;
  public currentDynamicPricingRules: DynamicPricingDTO[];
  public sourceCouriers: GlobalCourierRO[] = [];
  public targetCouriers: GlobalCourierRO[] = [];
  public dynamicPricingForm: FormGroup;

  constructor(
    public drawerRef: MtxDrawerRef<UpdateDynamicPricingComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: {
      company: Company;
      currentDynamicPricingRules: DynamicPricingDTO[];
      dynamicPricing?: DynamicPricingDTO;
    },
    private formBuilder: FormBuilder,
    private providersService: ProvidersService,
    private companyService: CompanyService,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    this.company = this.data.company;
    this.currentDynamicPricingRules = this.data.currentDynamicPricingRules;
    this.dynamicPricing = this.data.dynamicPricing;
    this.isEditing = this.dynamicPricing && this.dynamicPricing.id ? true : false;

    this.providersService.getEnabledGlobalProviders().subscribe(
      (res) => {
        const globalProviders: GlobalCourierRO[] = res.globalProviders;
        globalProviders.forEach((globalProvider) => {
          _.extend(globalProvider, {
            name: this.helpersService.convertShipmentProviderToString(globalProvider.provider),
          });
          if (globalProvider.childProviders && globalProvider.childProviders.length > 0) {
            globalProvider.childProviders.forEach((childProvider) => {
              _.extend(childProvider, {
                name: this.helpersService.convertShipmentProviderToString(childProvider.provider),
              });
            });
          }
        });
        globalProviders.sort((providerA, providerB) => {
          if (providerA.name > providerB.name) return 1;
          if (providerA.name < providerB.name) return -1;
          return 0;
        });
        this.sourceCouriers = globalProviders;
        this.targetCouriers = globalProviders.filter(
          // UPS should never be a target courier. we are not allowed to add any markups
          (globalProvider) => globalProvider.provider !== ShippingProviders.UPS
        );
        this.setupForm();
        this.loading = false;
      },
      (error) => {
        this.logger.error('Error Company Dynamic Pricing - GET Global Providers', error);
        this.snackBar.open('Problem retrieving global providers', '', {
          panelClass: 'error',
        });
        this.dismissDrawer();
      }
    );
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public saveDynamicPricing(): void {
    if (this.isEditing) {
      this.update();
    } else {
      this.create();
    }
  }

  private update() {
    this.loading = true;
    this.companyService.updateDynamicPricing(this.dynamicPricing.id, this.dynamicPricingForm.value).subscribe(
      (dynamicPricing) => {
        this.logger.log('Company Dynamic Pricing - UPDATE Dynamic Pricing', dynamicPricing);
        this.loading = false;
        this.drawerRef.dismiss(true);
      },
      (error) => {
        this.logger.error('Company Dynamic Pricing - UPDATE Dynamic Pricing', error);
        this.snackBar.open('Problem saving Dynamic Pricing', '', {
          panelClass: 'error',
        });
        this.dismissDrawer();
      }
    );
    this.loading = false;
    this.drawerRef.dismiss(true);
  }

  private create() {
    this.loading = true;
    const params = this.dynamicPricingForm.value;
    this.companyService.createDynamicPricing(parseInt(this.company.id), params).subscribe(
      (dynamicPricing) => {
        this.logger.log('Company Dynamic Pricing - CREATE Dynamic Pricing', dynamicPricing);
        this.loading = false;
        this.drawerRef.dismiss(true);
      },
      (error) => {
        this.logger.error('Company Dynamic Pricing - CREATE Dynamic Pricing', error);
        this.snackBar.open('Problem saving Dynamic Pricing', '', {
          panelClass: 'error',
        });
        this.dismissDrawer();
      }
    );
    this.loading = false;
    this.drawerRef.dismiss(true);
  }

  private setupForm() {
    // Rules:
    //  - if a provider is already present in any of the pricing rules as a source or target, it no longer can be selected as a target
    //  - if a provider is already present in any of the pricing rules as a target, it no longer can be selected as a source

    const providersUsedAsSourceCourier = this.currentDynamicPricingRules.map(
      (dynamicPricing) => dynamicPricing.sourceCourier
    );
    const providersUsedAsTargetCourier = this.currentDynamicPricingRules.map(
      (dynamicPricing) => dynamicPricing.targetCourier
    );
    const allProvidersUsed = [...new Set([...providersUsedAsSourceCourier, ...providersUsedAsTargetCourier])];

    this.sourceCouriers = this.sourceCouriers.filter(
      (globalCourier) => !providersUsedAsTargetCourier.includes(globalCourier.provider)
    );

    this.targetCouriers = this.targetCouriers.filter(
      (globalCourier) => !allProvidersUsed.includes(globalCourier.provider)
    );

    this.dynamicPricingForm = this.formBuilder.group({
      sourceCourier: [
        _.get(this.dynamicPricing, 'sourceCourier', this.getDefaultSourceCourier()),
        [Validators.required],
      ],
      targetCourier: [_.get(this.dynamicPricing, 'targetCourier', null), [Validators.required]],
      minMarginPercentageValue: [
        _.get(this.dynamicPricing, 'minMarginPercentageValue', null),
        [Validators.required, Validators.pattern(/^(-?.*[1-9])\d*\.?\d*$/), Validators.min(0), Validators.max(100)],
      ],
      beatPriceByPercentageValue: [
        _.get(this.dynamicPricing, 'beatPriceByPercentageValue', null),
        [Validators.required, Validators.pattern(/^(-?.*[1-9])\d*\.?\d*$/), Validators.min(0), Validators.max(100)],
      ],
    });
  }

  private getDefaultSourceCourier(): ShippingProviders {
    return this.sourceCouriers.find((courier) => courier.provider === ShippingProviders.UPS).provider;
  }
}
