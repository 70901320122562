import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { ShipmentsComponent } from '@app/pages/authenticated/pages/shipments/shipments.component';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { MtxButtonModule } from '@ng-matero/extensions/button';
import { NotesComponent } from '@app/pages/authenticated/pages/shipments/components/notes/notes.component';
import { ModalCreateReturnLabelComponent } from '@app/pages/authenticated/pages/shipments/components/modal-create-return-label/modal-create-return-label.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_SHIPMENTS] },
    canActivateChild: [ScopeGuard],
    children: [
      {
        path: '',
        component: ShipmentsComponent,
        data: {
          scopes: [AdminScopes.VIEW_SHIPMENTS],
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    MtxButtonModule,
  ],
  exports: [RouterModule],
  declarations: [
    ModalCreateReturnLabelComponent,
    NotesComponent,
    ShipmentsComponent,
  ],
})
export class ShipmentsModule {}
