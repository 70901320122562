import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CompanyService } from '@app/shared/services/company.service';
import { LoggerService } from '@app/shared/services/logger.service';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AdminScopes,
  AdminUser,
  UserService,
} from '@app/shared/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-billing-info',
  templateUrl: './billing-info.component.html',
})
export class BillingInfoComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  // @ts-ignore
  public billingInfoForm: FormGroup;
  public billingContactsArray: FormArray = new FormArray([]);
  public loading = true;
  public canEdit: boolean = false;
  public address: any;

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private userService: UserService,
    private logger: LoggerService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}
  ngOnInit() {
    this.loading = true;
    if (
      this.company &&
      this.user &&
      this.userService.hasAuthScope(this.user, [
        AdminScopes.VIEW_USERS_SETTINGS_BILLING,
      ])
    ) {
      this.canEdit = this.userService.hasAuthScope(this.user, [
        AdminScopes.MODIFY_USERS_SETTINGS_BILLING,
      ]);
      this.createForm();
    } else {
      this.router.navigate(['users']);
    }
  }

  public createForm(): void {
    if (this.company) {
      const { name, owner } = this.company;
      const companySettings = this.company?.settings || {};
      const address = companySettings?.billingAddress || this.company.address;
      const companyName = companySettings?.companyName || name;
      const companyAttentionTo = companySettings?.companyAttentionTo || `${owner?.firstName || ''} ${owner.lastName || ''}`;
      this.billingInfoForm = this.formBuilder.group({
        companyName: [
          { value: companyName, disabled: !this.canEdit },
          { validators: [Validators.required] }
        ],
        attentionTo: [
          {
            value: companyAttentionTo,
            disabled: !this.canEdit,
          }
        ],
        address: this.formBuilder.group({
          line1: [
            { value: address.line1, disabled: !this.canEdit },
            { validators: [Validators.required] }
          ],
          line2: [
            { value: address.line2, disabled: !this.canEdit },
            { validators: [] }
          ],
          additionalInfo: [
            { value: address?.additionalInfo || '', disabled: !this.canEdit },
            { validators: [] }
          ],
          city: [
            { value: address.city, disabled: !this.canEdit },
            { validators: [Validators.required] }
          ],
          postalCode: [
            { value: address.postalCode, disabled: !this.canEdit },
            { validators: [Validators.required] }
          ],
          country: [
            { value: address.country, disabled: !this.canEdit },
            { validators: [Validators.required] }
          ],
          province: [
            { value: address.province, disabled: !this.canEdit },
            { validators: [Validators.required] }
          ],
        }),
        billingContactsArray: this.formBuilder.array([]),
        noBillingStatement: [
          {
            value: companySettings?.noBillingStatement || false,
            disabled: !this.canEdit,
          },
          { validators: [Validators.required] },
        ],
        applyCreditCardFee: [
          {
            value: companySettings?.applyCreditCardFee || false,
            disabled: !this.canEdit,
          },
          { validators: [Validators.required] },
        ],
      });

      // Setup the quick access reference to the billing contact array
      this.billingContactsArray = this.billingInfoForm.get(
        'billingContactsArray'
      ) as FormArray;

      // Ensure we always have at least one
      if (
        this.company?.settings?.billingContacts &&
        Array.isArray(this.company?.settings?.billingContacts)
      ) {
        this.company?.settings?.billingContacts.forEach((contact) => {
          this.addRecipientGroup(
            contact.billingEmail,
            contact.billingFirstName
          );
        });
      } else {
        this.addRecipientGroup(
          this.company?.settings?.billingEmail ||
            this.company?.owner?.email ||
            '',
          this.company?.settings?.billingFirstName ||
            this.company?.owner?.firstName
        );
      }

      this.updateDisabled();
    }
  }

  public deleteRecipient(index) {
    this.billingContactsArray.removeAt(index);
  }

  public addRecipientGroup(email?, firstName?): void {
    this.billingContactsArray.push(
      this.formBuilder.group({
        billingEmail: [
          {
            value: email || '',
            disabled: !this.canEdit,
          },
          { validators: [Validators.required] },
        ],
        billingFirstName: [
          {
            value: firstName || '',
            disabled: !this.canEdit,
          },
          { validators: [Validators.required] },
        ],
      })
    );
    this.billingContactsArray.markAsUntouched();
  }

  public updateBillingInfo(): void {
    if (this.canEdit) {
      this.updateCompanyBillingInfo();
    }
  }

  public updateDisabled(): void {
    if (this.billingInfoForm && this.canEdit) {
      this.billingInfoForm.get('noBillingStatement').enable();
      this.billingInfoForm.get('applyCreditCardFee').enable();
      this.billingInfoForm.get('companyName').enable();
      this.billingInfoForm.get('attentionTo').enable();
      this.billingInfoForm.get('address').enable();
      this.billingContactsArray.enable();
      this.billingInfoForm.updateValueAndValidity();
    }
    this.loading = false;
  }

  private updateCompanyBillingInfo(): void {
    const formValues = this.billingInfoForm.value;
    const billingContacts = this.billingContactsArray.value;
    const updateDto = {
      settings: {
        companyName: formValues.companyName,
        companyAttentionTo: formValues.attentionTo,
        billingAddress: formValues.address,
        billingContacts,
        noBillingStatement: formValues.noBillingStatement,
        applyCreditCardFee: formValues.applyCreditCardFee,
      },
    };

    this.companyService.update(this.company.uuid, updateDto).subscribe(
      (response: any) => {
        this.logger.log(
          'Modals Billing Type - UPDATE Billing info',
          response
        );
        this.refreshCompany.emit();
        this.snackBar.open('Billing info updated', '', {
          panelClass: 'success',
        });
      },
      (err: Error) => {
        this.snackBar.open(
          `There was a problem updating the Billing info. ${_.get(
            err,
            'error.message',
            ''
          )}`,
          '',
          { panelClass: 'error' }
        );
        this.logger.error(
          'Modals Billing Type -UPDATE Billing info error',
          err
        );
      }
    );
  }
}
