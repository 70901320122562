import { Component, Input, OnInit } from '@angular/core';
import { AdminUser, UserService } from '@app/shared/services/user.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { SessionStorageService } from 'ngx-webstorage';
import { ReportsService } from '@app/shared/services/reports.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DateFormats,
  DEFAULT_LOCALE,
  ReportCardGroup,
  SupportedAirports,
} from '@app/shared/constants';
import { DateTime } from 'luxon';
import { formatNumber } from '@angular/common';
import * as _ from 'lodash';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-acc-reports',
  templateUrl: './acc-reports.component.html',
})
export class ACCReportsComponent implements OnInit {
  @Input() user: AdminUser;
  @Input() canViewACCReports: boolean = false;

  public loading: boolean = true;
  public filterForm: FormGroup | undefined;
  public ReportCardGroupTypes = ReportCardGroup;

  public daterange = {
    start: DateTime.now().minus({ day: 7 }).toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  };

  public range = new FormGroup({
    start: new FormControl<Date | null>(this.daterange.start),
    end: new FormControl<Date | null>(this.daterange.end),
  });
  public supportedAirports = SupportedAirports;

  public downloadingReportData: boolean = false;

  // SHIPMENTS CARDS
  // Total Shipments
  public loadingTotalShipmentsCard = false;
  public loadingTotalShipmentsCardFailed = false;
  public totalShipments: string;

  constructor(
    private formBuilder: FormBuilder,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private reportsService: ReportsService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    if (this.canViewACCReports) {
      this.createForm();
      this.getAllInformation();
      this.loading = false;
    }
  }

  // fetch all the data for the cards
  public getAllInformation(): void {
    if (this.canViewACCReports) {
      if (!this.isAnyCardLoading()) {
        this.refreshTotalShipmentsCard();
      }
    }
  }

  public isAnyCardLoading(): boolean {
    return this.loadingTotalShipmentsCard;
  }

  // SHIPMENT CARDS
  public refreshTotalShipmentsCard(): void {
    this.loadingTotalShipmentsCard = true;
    this.loadingTotalShipmentsCardFailed = false;
    let query = this.getFilterQuery();
    query.report_card_group = ReportCardGroup.ACC_SHIPMENTS;

    this.reportsService.getSummaryCard(query).subscribe(
      (res) => {
        this.totalShipments = formatNumber(res.totalShipments, DEFAULT_LOCALE);
        this.loadingTotalShipmentsCard = false;
      },
      (err: Error) => {
        this.loadingTotalShipmentsCard = false;
        this.loadingTotalShipmentsCardFailed = true;
        this.logger.error('GET total shipments report error', err);
      }
    );
  }

  // DOWNLOAD CARD DATA
  public downloadCardData(cardType: ReportCardGroup): void {
    this.downloadingReportData = true;
    const query: any = this.getFilterQuery();
    query.report_card_group = cardType;
    this.reportsService.getSummaryCardData(query).subscribe(
      (res) => {
        if (res.data) {
          const mimeType: string = 'text/csv';
          const startDate: string = DateTime.fromJSDate(this.range.value.start)
            .startOf('day')
            .toFormat(DateFormats.DATE_ISO);
          const endDate: string = DateTime.fromJSDate(this.range.value.end)
            .startOf('day')
            .toFormat(DateFormats.DATE_ISO);
          const downloadFileName: string = `${query.report_card_group}-report-${startDate}-${endDate}.csv`;
          const blob = this.helpersService.b64toBlob(res.data, mimeType);
          FileSaver.saveAs(blob, downloadFileName);
          this.downloadingReportData = false;
        } else if (res.error) {
          this.downloadingReportData = false;
          this.snackBar.open(res.error, '', {
            panelClass: 'error',
          });
        }
      },
      (err: Error) => {
        this.downloadingReportData = false;
        this.logger.error('GET accounts report data error', err);
      }
    );
  }

  // INTERNAL HELPER FUNCTIONS
  private createForm() {
    // Create the base form
    this.filterForm = this.formBuilder.group({
      originAirports: ['', []],
      destinationAirports: ['', []],
      companyName: ['', []],
    });
  }

  private getFilterQuery(): any {
    const query = {
      start_date: DateTime.fromJSDate(this.range.value.start)
        .startOf('day')
        .toUTC()
        .toISO({ includeOffset: false }),
      end_date: DateTime.fromJSDate(this.range.value.end)
        .endOf('day')
        .toUTC()
        .toISO({ includeOffset: false }),
      report_card_group: undefined,
    };

    if (
      !_.isEmpty(this.filterForm.value.originAirports) &&
      _.isArray(this.filterForm.value.originAirports)
    ) {
      query['origin_airport'] = this.filterForm.value.originAirports;
    }

    if (
      !_.isEmpty(this.filterForm.value.destinationAirports) &&
      _.isArray(this.filterForm.value.destinationAirports)
    ) {
      query['destination_airport'] = this.filterForm.value.destinationAirports;
    }
    if (
      this.filterForm.value.companyName &&
      this.filterForm.value.companyName.length > 0
    ) {
      query['company_name'] = this.filterForm.value.companyName;
    }

    return query;
  }
}
