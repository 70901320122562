import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company, CompanyNote } from '@app/pages/authenticated/pages/users/users.model';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { FormBuilder } from '@angular/forms';
import { CompanyService } from '@app/shared/services/company.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UpdateNoteComponent
} from '@app/pages/authenticated/pages/users/settings/components/notes/update-note/update-note.component';
import { CompanyNoteType, DateFormats, PanelWidths } from '@app/shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { PageEvent } from '@angular/material/paginator';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
})
export class NotesComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  public loading = true;
  public companyNotes: CompanyNote[] = [];
  public paginatedCompanyNotes: CompanyNote[] = [];
  public companyNoteType: any = CompanyNoteType;
  public DATE_YEAR: string = DateFormats.DATE_YEAR;

  public currentPage: number = 0;
  public itemsPerPage: number = 10;
  public displayedColumns: string[] = ['date', 'type', 'note', 'actions'];
  public canEdit: boolean = false;

  constructor(
    private dialog: MatDialog,
    private drawer: MtxDrawer,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private userService: UserService,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
  }
  ngOnInit() {
    this.loading = true;
    if (this.company && this.user && this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS_SETTINGS_NOTES])) {
      this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_USERS_SETTINGS_NOTES]);
      this.companyNotes = this.company.notes;
      this.setupPaginatedCompanyNotes();
      this.loading = false;
    } else {
      this.router.navigate(['users']);
    }
  }

  public addNote(): void {
    if (this.canEdit) {
      const drawerRef = this.drawer.open(
        UpdateNoteComponent,
        {
          width: PanelWidths.desktopFull,
          disableClose: true,
          closeOnNavigation: false,
          data: { company: this.company }
        }
      );
      drawerRef.afterDismissed().subscribe((result => {
        if (result) {
          this.refreshCompany.emit();
          this.snackBar.open('Note was saved', '', { panelClass: 'success' });
        }
      }));
    }
  }

  public updateNote(note: CompanyNote): void {
    if (this.canEdit) {
      const drawerRef = this.drawer.open(
        UpdateNoteComponent,
        {
          width: PanelWidths.desktopFull,
          disableClose: true,
          closeOnNavigation: false,
          data: { company: this.company, note }
        }
      );
      drawerRef.afterDismissed().subscribe((result => {
        if (result) {
          this.refreshCompany.emit();
          this.snackBar.open('Note was updated', '', { panelClass: 'success' });
        }
      }));
    }
  }

  public deleteNote(note: CompanyNote): void {
    if (this.canEdit) {
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: 'Delete Note',
          content: `Are you sure you want to delete this note?`,
          confirmBtnLabel: 'Delete',
          btnColor: 'warn',
        }
      });
      dialogRef.afterClosed().subscribe((result)=> {
        if (result) {
          this.companyService.deleteNote(this.company.uuid, { body: { noteIds: [note.id] }}).subscribe(() => {
            this.refreshCompany.emit();
            this.snackBar.open('Note was deleted', '', { panelClass: 'success' });
          });
        }
      });
    }
  }

  public pageChanged(pageEvent: PageEvent): void {
    this.currentPage = pageEvent.pageIndex;
    const end = this.itemsPerPage * (this.currentPage + 1);
    const start = end - this.itemsPerPage;
    this.paginatedCompanyNotes = this.companyNotes.slice(start, end);
  }

  public getNoteTypeClass(type: CompanyNoteType): string {
    switch (type) {
      case CompanyNoteType.HAPPY:
        return 'icon-smile';
      case CompanyNoteType.NEUTRAL:
        return 'icon-neutral';
      case CompanyNoteType.UNHAPPY:
        return 'icon-angry';
      case CompanyNoteType.CONFUSED:
      default:
        return 'icon-confusing';
    }
  }

  private setupPaginatedCompanyNotes() {
    this.paginatedCompanyNotes = this.companyNotes.slice(0, this.itemsPerPage);
  }
}
