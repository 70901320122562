<ng-container *ngIf='!loading, else isLoading'>
  <div class='container--full-width pl--none'>
    <div class='row'>
      <div class='column--12 column-xl--8 column-xxl--4'>
        <mat-form-field class="w-100">
          <mat-label>Search tracking number</mat-label>
          <input matInput type="text" [(ngModel)]="trackingNumber" (keyup.enter)="searchByTrackingNumber()">
          <button *ngIf="trackingNumber" matSuffix mat-icon-button aria-label="Clear" (click)="trackingNumber=''">
            <mat-icon>close</mat-icon>
          </button>
          <button *ngIf="trackingNumber" matSuffix mat-icon-button aria-label="Search" color='primary'
                  (click)='searchByTrackingNumber()'>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class='row'>
      <div class='column--12 column-xxl--9'>
        <ng-container *ngIf='!loadingShipments'>
          <ng-container *ngIf='shipment, else noShipment'>
            <div class='row'>
              <div class='column--12'>
                <div class='row my--lg'>
                  <div class='column pl--none'>
                    <h2 class='my--none lineheight--none no-bold' notranslate>
                      <img
                        height="25px"
                        [src]="shipment.providerImage"
                        [matTooltip]="shipment.providerName"
                        matTooltipPosition="above"
                      >
                      {{ shipment.tracking }}
                      <mat-icon mat-mini-fab
                                *ngIf="shipment.ecommerceShipment"
                                class='icon-cart'
                                [matTooltip]="getEcommerceText(shipment)"
                                matTooltipPosition="above"
                      ></mat-icon>
                    </h2>
                    <span class='text--gray-optional text--xs'>Shipment created: <span
                      notranslate>{{ shipment.createdAt | timeToString: DATE_TIME_YEAR }}</span></span>
                  </div>
                  <div class='column'>
                    <strong class='text--gray-optional text--xs'>Tracking Status</strong><br />
                    <div class='pt--xs'></div>
                    <div class='pill-box pill-status'
                         [ngClass]="shipment.trackingStatus">{{ shipment.trackingStatusVerbose }}
                    </div>
                  </div>
                  <div class='column'>
                    <strong class='text--gray-optional text--xs'>Status (internal)</strong><br />
                    <div class='pt--xs'>
                      <div class='pill-box pill-status' [ngClass]='shipment.status'>{{ shipment.statusVerbose }}</div>
                    </div>
                  </div>
                  <div class='column'>
                    <strong class='text--gray-optional text--xs'>Company / User</strong><br />
                    <ng-container *ngIf='canViewUsers, else simpleName'>
                      <a mat-button notranslate class='text--sm p--none m--none' aria-label="Go to users"
                         color='primary' (click)='goToUsersTab()'>
                        {{ companyName }} / {{ userFirstName }} {{ userLastName }}
                        <i class='icon-arrow-circle-right'></i>
                      </a>
                    </ng-container>
                    <ng-template #simpleName>
                      <p class='text--sm pb--none my--none' style='padding-top: 11px;' notranslate>{{ companyName }}
                        / {{ userFirstName }} {{ userLastName }}</p>
                    </ng-template>
                  </div>
                  <div class='column' *ngIf='canViewRecon'>
                    <strong class='text--gray-optional text--xs'>Recon</strong><br />
                    <a *ngIf="shipment.rates.isReconciled" mat-button class='text--sm p--none m--none'
                       aria-label="Go to users" color='primary' (click)='goToReconTab(shipment)'>
                      Reconciled
                      <i class='icon-arrow-circle-right'></i>
                    </a>
                    <div *ngIf="!shipment.rates.isReconciled" class='pt--xs'>
                      <span class='text--sm'>Not Reconciled</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='column--12 column-lg--8'>
                <mat-card>
                  <mat-card-content>
                    <div class='row'>
                      <div class='column--12 column-md--5'>
                        <p class='my--none pb--xs border-bottom'><strong>Sender</strong></p>
                        <p class='text--sm mt--xs mb--md lineheight-larger' notranslate>
                          {{ shipment.senderInfo.name }}<span
                          *ngIf='shipment.senderInfo.attentionTo'> / {{ shipment.senderInfo.attentionTo }}</span><br />
                          <span *ngIf='shipment.senderInfo.email'>{{ shipment.senderInfo.email }}<br /></span>
                          <span *ngIf='shipment.senderInfo.fullPhone'>{{ shipment.senderInfo.fullPhone }}<br /></span>
                          <br />
                          {{ shipment.senderInfo.address.line1 }}<br />
                          <span
                            *ngIf='shipment.senderInfo.address.line2 && shipment.senderInfo.address.line2.length > 0 '>{{ shipment.senderInfo.address.line2 }}
                            <br /></span>
                          {{ shipment.senderInfo.address.city }}, {{ shipment.senderInfo.address.province }}<br />
                          {{ shipment.senderInfo.address.postalCode }}<br />
                          <span
                            *ngIf='shipment.senderInfo.formattedCostCenter'>Cost Center: {{ shipment.senderInfo.formattedCostCenter }}</span>
                        </p>

                        <p class='my--none pb--xs border-bottom'><strong>Recipient</strong></p>
                        <p class='text--sm mt--xs mb--md lineheight-larger' notranslate>
                          {{ shipment.recipientInfo.name }}<span
                          *ngIf='shipment.recipientInfo.attentionTo'> / {{ shipment.recipientInfo.attentionTo }}</span><br />
                          <span *ngIf='shipment.recipientInfo.email'>{{ shipment.recipientInfo.email }}<br /></span>
                          <span *ngIf='shipment.recipientInfo.fullPhone'>{{ shipment.recipientInfo.fullPhone }}
                            <br /></span>
                          <br />
                          {{ shipment.recipientInfo.address.line1 }}<br />
                          <span
                            *ngIf='shipment.recipientInfo.address.line2 && shipment.recipientInfo.address.line2.length > 0 '>{{ shipment.recipientInfo.address.line2 }}
                            <br /></span>
                          {{ shipment.recipientInfo.address.city }}, {{ shipment.recipientInfo.address.province }}<br />
                          {{ shipment.recipientInfo.address.postalCode }}<br />
                          <span *ngIf='shipment.recipientInfo.buzzer'>Buzzer: {{ shipment.recipientInfo.buzzer }}</span>
                        </p>

                        <p class='my--none pb--xs border-bottom'><strong>Items</strong></p>
                        <div class='mt--xs mb--md text--sm' notranslate
                             *ngFor='let parcel of shipment.items, let i = index'>
                          <span>{{ getPackageDetailsString(parcel, shipment) }}</span>
                        </div>

                        <p class='my--none pb--xs border-bottom'><strong>Additional details</strong></p>
                        <ng-container *ngIf='noReferences, else showReferences'>
                          <div class='text--sm mt--xs mb--none' notranslate>N/A</div>
                        </ng-container>
                        <ng-template #showReferences>
                          <div class='contact-info text--sm mt--xs mb--none'>
                            <div *ngIf="additionalDetails.reference1"><span class='pr--xs'>Reference 1:</span> <span
                              notranslate>{{ additionalDetails.reference1 }}</span></div>
                            <div *ngIf="additionalDetails.reference2"><span class='pr--xs'>Reference 2:</span> <span
                              notranslate>{{ additionalDetails.reference2 }}</span></div>
                            <div *ngIf="additionalDetails.additionalNote"><span class='pr--xs'>Note:</span> <span
                              notranslate>{{ additionalDetails.additionalNote }}</span></div>
                          </div>
                        </ng-template>
                      </div>
                      <div class='column--12 column-md--7'>
                        <p class='mt--none pb--xs border-bottom'><strong>Service</strong></p>
                        <div class='row'>
                          <div class='column--12'>
                            <div class='service-summary'>
                              <div *ngIf="shipment.service && shipment.service.provider, else noService">
                                <div class='align-center'><img
                                  [ngClass]="'summary-logo-' + shipment.providerCode"
                                  [src]="shipment.providerLogoSrc"
                                /></div>
                                <div class='align-center semi-bold text--sm'>{{ shipment.service.name }}</div>
                              </div>
                              <ng-template #noService>
                                <div class='text--sml'>No service information provided</div>
                              </ng-template>
                              <ng-container *ngIf='canViewShipmentDetails'>
                                <div class='row border-bottom pt--sm pb--sm' *ngIf='shipment.service.type'>
                                  <div class='column--6 text--sm'>Account type</div>
                                  <div class='column--6 text--sm align-right'>
                                    <span *ngIf="!shipment.service.thirdParty && shipment.service.type === 'ma'">Machool account</span>
                                    <span *ngIf="!shipment.service.thirdParty && shipment.service.type === 'byoa'">Your account</span>
                                    <span *ngIf="shipment.service.thirdParty">3rd party account</span>
                                  </div>
                                </div>
                                <div class='border-bottom py--sm' *ngIf='shipment.service.thirdParty'>
                                  <div class='text--sm'>Billed to 3rd party</div>
                                  <ng-container [ngSwitch]="shipment.service.provider">
                                    <ng-container *ngSwitchCase="shippingProviders.CANADA_POST">
                                      <div class='row pt--sm'>
                                        <div class='column--6 text--xs'><span class='pl--md'>Contract ID</span></div>
                                        <div
                                          class='column--6 text--xs align-right'>{{ shipment.service.thirdParty.thirdPartyContractId || shipment.service.thirdParty.contractId }}
                                        </div>
                                      </div>
                                      <div class='row pt--sm'>
                                        <div class='column--6 text--xs'><span class='pl--md'>Contract ID</span></div>
                                        <div
                                          class='column--6 text--xs align-right'>{{ shipment.service.thirdParty.thirdPartyCustomerId || shipment.service.thirdParty.customerId }}
                                        </div>
                                      </div>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                      <div class='row pt--sm'>
                                        <div class='column--6 text--xs'><span class='pl--md'>Account number</span></div>
                                        <div
                                          class='column--6 text--xs align-right'>{{ shipment.service.thirdParty.thirdPartyAccount || shipment.service.thirdParty.accountNumber }}
                                        </div>
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                </div>
                              </ng-container>
                            </div>
                            <div class='cost-summary-container text--sm' *ngIf='canViewShipmentDetails'>
                              <div
                                class='cost-summary'
                                *ngFor="let rate of rates; let i = index"
                                [ngClass]="[
                                            ['thirdPartyInsurance', 'deliveryToPickupLocation'].includes(rate.type) ? 'third-party-insurance-row' : '',
                                            ['thirdPartyInsurance', 'deliveryToPickupLocation'].includes(rate.type) && !shipment.hasOptions ? 'mt-2' : '']"
                              >
                                <ng-container
                                  *ngIf="['thirdPartyInsurance', 'deliveryToPickupLocation'].includes(rate.type) && !shipment.hasOptions">
                                  <div class='row mt--sm third-party-option-header'>
                                    <div class='column--12 cost-summary-labels'>
                                      <span>Options</span>
                                    </div>
                                  </div>
                                </ng-container>
                                <div
                                  class='row cost-summary-rows mt--sm'
                                  *ngIf="showSection(rate)"
                                  [ngClass]="rate.adjustmentGroup ? 'has-adjustment' : ''"
                                >
                                  <ng-container *ngIf="rate.label === 'total'">
                                    <div class='column--12'>
                                      <div class='border-bottom mb--sm pt--sm'></div>
                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="!rate.adjustmentGroup && showSection(rate)">
                                    <div
                                      class='column--12 cost-summary-labels'
                                      [ngClass]="[i === (rates.length - 1) ? 'column-md--5' : 'column-md--9', rate.adjustmentGroup ? 'btn-link' : '']"
                                    >
                                      <span
                                        [ngClass]="['thirdPartyInsurance', 'deliveryToPickupLocation'].includes(rate.type) ? 'text--xs' : ''"
                                        [innerHTML]='rate.label'></span>
                                    </div>
                                    <div
                                      class='column--12 cost-summary-values align-right'
                                      [ngClass]="i === (rates.length - 1) ? 'column-md--7' : 'column-md--3'"
                                    >
                                      <span *ngIf="showAmount(rate)"
                                            [ngClass]="rate.type === 'thirdPartyInsurance' ? 'text--xs' : ''">{{ getRateValue(rate.amount) }}</span>
                                      <span *ngIf="rate.valueIncluded">Included</span>
                                      <span
                                        *ngIf="rate.label === 'taxes' && rate.amount === 0">{{ getRateValue(rate.amount) }}</span>
                                      <span *ngIf="rate.label === 'discount'"
                                            [ngClass]="rate.amount < 0 ? 'text--danger' : ''">{{ getRateValue(rate.amount) }}</span>
                                      <div *ngIf="rate.label === 'total'">
                                        <strong>{{ getRateValue(rate.amount) }}</strong>
                                        <small
                                          class='cost-summary-labels'
                                          *ngIf="rate.discount && rate.discount.amount > 0 && shipment.accountType === 'ma'"
                                          ,
                                          [ngClass]="rate.discount.amount > 0 ? 'text-danger' : ''"
                                        >{{ rate.discount.label }} {{ getRateValue(rate.discount.amount) }}</small>
                                      </div>
                                      <ng-container *ngIf="rate.type !== 'thirdPartyInsurance' && rate.infoMessage">
                                        <button
                                          mat-icon-button
                                          aria-label="Info"
                                          matTooltip="{{rate.infoMessage}}"
                                          matTooltipPosition="above"
                                        >
                                          <mat-icon class='icon-info'></mat-icon>
                                        </button>
                                      </ng-container>

                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="rate.adjustmentGroup && showSection(rate)">
                                    <div class='column--8 cost-summary-labels text--xs'>
                                      <ng-container *ngIf="rate.stripeReceiptUrl, else noReceipt">
                                        <a class='pl--md text--primary cursor-pointer'
                                           (click)="getReceipt(rate.stripeReceiptUrl)">
                                          <span class="display-inline-block" [innerHTML]='rate.label'></span>
                                        </a>
                                      </ng-container>
                                      <ng-template #noReceipt>
                                        <span class='pl--md display-inline-block' [innerHTML]='rate.label'></span>
                                      </ng-template>
                                    </div>
                                    <div class='column--4 cost-summary-value align-right text--xs'>
                                      <span *ngIf="showAmount(rate)">-{{ getRateValue(rate.amount) }}</span>
                                    </div>
                                  </ng-container>
                                  <div class='cost-summary-list mb--sm w-100 text--xs'
                                       *ngIf="rate.list && rate.list.length > 0">
                                    <ng-container *ngFor="let item of rate.list; let idx = index">
                                      <div class='row' [ngClass]="{ 'mt--sm': idx > 0 }"
                                           *ngIf="rate.label !== 'taxes' || (rate.label === 'taxes' && item.amount !== 0)">
                                        <div class='column--8 cost-summary-labels'>
                                          <!--                                {{ rate.label !== 'taxes' ? (item.name | translate) : (getTaxRatesLabel(item)) }}-->
                                          <span
                                            class='ml--md'>{{ rate.label !== 'taxes' ? (item.name) : (getTaxRatesLabel(item)) }}</span>
                                        </div>
                                        <div class='column--4 cost-summary-values align-right'
                                             *ngIf="item.amount !== 0"> &nbsp;
                                          <span
                                            *ngIf="item.code === 'RETURN_LABEL'"
                                            class='text--primary'
                                            matTooltip="You will only be charged if your return label is used. The return shipment will be the same service without options}"
                                            matTooltipPosition="above"
                                          >{{ getRateValue(item.amount) }}</span>
                                          <span
                                            *ngIf="item.code !== 'RETURN_LABEL'">{{ getRateValue(item.amount) }}</span>
                                        </div>
                                        <ng-container *ngIf="!shipment.service.thirdParty, else thirdParty">
                                          <div class='column--4 cost-summary-values align-right'
                                               *ngIf="item.amount === 0">
                                            <ng-container *ngIf="item.code === 'RETURN_LABEL', else included">
                                            <span
                                              class='text--primary'
                                              *ngIf="!shipment.service.thirdParty"
                                              matTooltip="You will only be charged if your return label is used. The return shipment will be the same service without options}"
                                              matTooltipPosition="above"
                                            >Selected</span>
                                            </ng-container>
                                            <ng-template #included>
                                              {{ getRateValue('Included') }}
                                            </ng-template>
                                          </div>
                                        </ng-container>
                                        <ng-template #thirdParty>
                                          <div class='column--4 cost-summary-values align-right'>-</div>
                                        </ng-template>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class='column--12 column-lg--4'>
                <mat-card>
                  <mat-card-content>
                    <mat-nav-list class='py--none'>
                      <mat-list-item mat-list-item *ngIf='shipment.tracking' (click)="openTracking(shipment)">
                        <div matListItemTitle><span class='text--primary-green'>View Tracking Detail</span></div>
                      </mat-list-item>
                      <mat-list-item mat-list-item
                                     *ngIf="shipment.returnTrackings && shipment.returnTrackings.length > 0"
                                     (click)="openTracking(shipment, 'return')">
                        <div matListItemTitle><span class='text--primary-green'>View Return Tracking Detail</span></div>
                      </mat-list-item>
                      <mat-divider></mat-divider>
                      <mat-list-item mat-list-item (click)="getDocuments(shipment, 'labels')">
                        <div matListItemTitle><span class='text--primary-green'>Download Shipping Label</span></div>
                      </mat-list-item>
                      <mat-list-item mat-list-item *ngIf='shipment.hasReturnLabel'
                                     (click)="getDocuments(shipment, 'return-labels')">
                        <div matListItemTitle><span class='text--primary-green'>Download Return Label</span></div>
                      </mat-list-item>
                      <mat-list-item mat-list-item *ngIf='canViewManifests && shipment.manifest'
                                     (click)="getDocuments(shipment, 'manifests')">
                        <div matListItemTitle><span class='text--primary-green'>Download Manifest</span></div>
                      </mat-list-item>

                      <mat-list-item *ngIf='canCreateReturns && !shipment.hasReturnLabel && isDomesticAccShipment'
                                     mat-list-item (click)="createReturnLabel(shipment)">
                        <div matListItemTitle><span class='text--primary-green'>Create return label</span></div>
                      </mat-list-item>

                      <ng-container *ngIf='canEdit'>
                        <mat-divider></mat-divider>
                        <mat-list-item *ngIf='shipment.isCPNC' mat-list-item
                                       (click)="openCanadaPostReceiptModal(shipment)">
                          <div matListItemTitle><span class='text--primary-green'>View Receipt</span></div>
                        </mat-list-item>
                        <mat-list-item *ngIf='shipment.ediInvoice' mat-list-item
                                       (click)="openEdiInvoiceModal(shipment)">
                          <div matListItemTitle><span class='text--primary-green'>View EDI Invoice</span></div>
                        </mat-list-item>
                        <mat-list-item *ngIf="shipment.accountType !== 'byoa' && shipment.adminChargeDetails"
                                       mat-list-item (click)="openAdminChargeDetailsModal(shipment)">
                          <div matListItemTitle><span class='text--primary-green'>View Admin Charge Details</span></div>
                        </mat-list-item>
                        <mat-list-item *ngFor="let invoice of shipment.adminShipmentInvoices"
                                       (click)="openInvoiceChargeDetailsModal(invoice)">
                          <div matListItemTitle><span
                            class='text--primary-green'>Download Invoice {{ invoice.invoiceNumber }}</span></div>
                        </mat-list-item>
                        <mat-list-item *ngIf='shipment.isCI' mat-list-item
                                       (click)="getDocuments(shipment, 'commercial-invoices')">
                          <div matListItemTitle><span class='text--primary-green'>Download Commercial Invoice</span>
                          </div>
                        </mat-list-item>
                        <mat-list-item *ngIf='shipment.codReceipt' mat-list-item
                                       (click)="getDocuments(shipment, 'cod-receipts')">
                          <div matListItemTitle><span class='text--primary-green'>Download
                            {{ shipment.provider !== 'purolator' ? 'CoD Receipt' : 'ExpressCheque® Receipt' }}
                        </span></div>
                        </mat-list-item>
                        <mat-list-item *ngIf='shipment.codReturn' mat-list-item
                                       (click)="getDocuments(shipment, 'cod-return-labels')">
                          <div matListItemTitle><span class='text--primary-green'>Download ExpressCheque® Return</span>
                          </div>
                        </mat-list-item>
                        <mat-list-item *ngIf='shipment.highValueReport' mat-list-item
                                       (click)="getDocuments(shipment, 'high-value-reports')">
                          <div matListItemTitle><span class='text--primary-green'>Download High Value Report</span>
                          </div>
                        </mat-list-item>
                        <mat-list-item class="pr--none" *ngIf='shipment.hasThirdPartyInsurance' mat-list-item
                                       (click)="getThirdPartyInsuranceCertificate(shipment)">
                          <div matListItemTitle>
                            <span class='text--primary-green'>Download Insurance certificate</span>
                            <app-loading [isInline]='true' *ngIf='gettingCertificate'></app-loading>
                          </div>
                        </mat-list-item>
                        <ng-container *ngIf='!shipment.hasBeenCancelled'>
                          <mat-divider></mat-divider>
                          <mat-list-item mat-list-item (click)="setStatus(shipmentStatuses.CANCELLED)">
                            <div matListItemTitle><span
                              class='text--danger'>Change status (internal) to Cancelled</span></div>
                          </mat-list-item>
                        </ng-container>
                      </ng-container>
                    </mat-nav-list>
                  </mat-card-content>
                </mat-card>

                <mat-card class='mt--md' *ngIf="shipment.provider === 'rivo'">
                  <mat-card-content>
                    <p class='mt--none pb--xs'><strong>Air Waybill</strong></p>
                    <div class='text--sm mt--xs mb--none' notranslate>{{ shipment.airWaybill || 'N/A' }}</div>
                  </mat-card-content>
                </mat-card>

                <mat-card class='mt--md' *ngIf='canViewShipmentDetails'>
                  <mat-card-content>
                    <ng-container *ngIf='shipment.note'>
                      <p class='mt--none pb--xs'><strong>Tags</strong></p>
                      <div class='text--sm mt--xs mb--none' [title]='shipment.note' notranslate>{{ shipment.note }}
                      </div>
                    </ng-container>
                    <ng-container>
                      <mat-divider class="my--md" *ngIf='shipment.note'></mat-divider>
                      <div class='pb--xs row row--valign-center'>
                        <div class='column--6'><strong>Note (internal)</strong></div>
                        <div class='column--6 align-right'>
                          <button mat-button color='primary' (click)='addInternalNote()'>
                            <span *ngIf='shipment.csNote'>Edit</span>
                            <span *ngIf='!shipment.csNote'>Add</span>
                            Note
                          </button>
                        </div>
                      </div>
                      <div class='text--sm mt--xs pb--xs' notranslate>
                        <span *ngIf='shipment.csNote'>{{ shipment.csNote }}</span>
                        <span *ngIf='!shipment.csNote'>N/A</span>
                      </div>
                      <div *ngIf='shipment.doNotBillAdjustments' class='text--sm mt--xs mb--none pt--xs border-top'
                           notranslate>
                        <span class="semi-bold">Do not bill adjustment: <mat-icon
                          class='icon-checked mat-icon-sm text--primary-green pt--xs'></mat-icon></span>
                      </div>
                    </ng-container>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </ng-container>
          <ng-template #noShipment>
            <div class='row justify-content-center mt--lg' *ngIf='shipmentNotFound'>
              <div class='column--12 column-md--4 align-center'>
                <img src='{{ CDN_URL }}/admin/images/empty_animated.svg' />
                <p>No shipment found</p>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
