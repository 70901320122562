import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { environment } from '../../../../../../../../environments/environment';
import { ShippingProviders } from '@app/shared/constants';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProvidersService } from '@app/shared/services/providers.service';
import * as _ from 'lodash';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-couriers',
  templateUrl: './couriers.component.html',
  styleUrls: ['./couriers.component.scss'],
})
export class CouriersComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  public error: boolean = false;
  public errors: Array<any> = [];
  public loading: boolean = false;
  public loadingProviders: boolean = false;
  public notifications: Array<any> = [];
  public provider: string = null;
  public providers: any = [];

  public isQCAccount: boolean = false;
  public companySettings: any;
  public isSupportUser = false;
  public hasRivoSetupCorrectly = false;

  private token: string = null;
  private params: any = {};
  private enabledGlobalProviders: any;

  // UPS MA
  public license: string;
  public upsMaAccount: any;
  public canEdit: boolean = false;

  constructor(
    private providersService: ProvidersService,
    private userService: UserService,
    private helpersService: HelpersService,
    private router: Router,
    private logger: LoggerService,
    private snackBar: MatSnackBar
  ) {}
  ngOnInit() {
    this.loading = true;
    if (
      this.company &&
      this.user &&
      this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS_SETTINGS_COURIERS])
    ) {
      this.companySettings = this.company.settings || {};
      this.isQCAccount = (this.company.address.province || '').toUpperCase() === 'QC';
      this.hasRivoSetupCorrectly = this.companySettings.aircanada?.originAirport;
      this.loading = false;
    } else {
      this.router.navigate(['users']);
    }
  }

  private getProviders() {
    this.providersService.get().subscribe(
      (res: any) => {
        this.logger.log('Configuration couriers - GET providers', res);
        const providersList = this.providersService.getProvidersList();
        this.providers = [];

        _.forEach(res, (provider: any) => {
          // configured providers
          const providerData = _.find(providersList, (item: any) => {
            return item.provider === provider.provider;
          });

          provider.machoolAccount = providerData.machoolAccount;
          provider.name = providerData.name;

          if (provider.provider === ShippingProviders.CANADA_POST && provider.byoaConfiguration) {
            // Canada Post does not have an account number
            provider.byoaConfiguration.account = provider.byoaConfiguration.customer;
          }

          if (provider.byoaConfiguration) {
            provider.account = provider.byoaConfiguration.account;
          }
          provider.accountName =
            provider.account && provider.account.length > 7 ? `***${provider.account.substr(-4)}` : provider.account;
          this.providers.push(provider);
        });

        _.each(providersList, (provider: any) => {
          // providers not configured
          const providerData = _.find(this.providers, (item: any) => {
            return item.provider === provider.provider;
          });
          if (!providerData) {
            this.providers.push(provider);
          }
        });

        // Sort the regular providers
        this.providers = _.sortBy(this.providers, ['provider']);
        // this.upsMaAccount = this.providersUpsService.findProviderMaAccount(this.providers, ShippingProviders.UPS);
        // this.checkDisabledProviders();
        // this.getEnabledGlobalShippingProviders();
      },
      (err: Error) => this.logger.error('Configuration couriers - GET providers error', err)
    );
  }
}
