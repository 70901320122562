import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import {
  AdminScopes,
  AdminUser,
  UserService,
} from '@app/shared/services/user.service';
import { CompanyService } from '@app/shared/services/company.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { PanelWidths } from '@app/shared/constants';
import { UpdateCourierModifierComponent } from '@app/pages/authenticated/pages/users/settings/components/courier-modifiers/update-courier-modifier/update-courier-modifier.component';
import { CourierModifierDTO } from '@app/shared/services/providers.service';
import * as _ from 'lodash';
import { HelpersService } from '@app/shared/services/helpers.service';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-courier-modifiers',
  templateUrl: './courier-modifiers.component.html',
})
export class CourierModifiersComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  public loading: boolean = false;
  public canEdit: boolean = false;
  public courierModifiers: CourierModifierDTO[] = [];
  public displayedColumns: string[] = [
    'provider',
    'markups',
    'delivery_date',
    'actions',
  ];

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private logger: LoggerService,
    private helpersService: HelpersService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private drawer: MtxDrawer
  ) {}

  ngOnInit() {
    this.loading = true;
    if (
      this.company &&
      this.user &&
      this.userService.hasAuthScope(this.user, [
        AdminScopes.VIEW_USERS_SETTINGS_COURIER_MODIFIERS,
      ])
    ) {
      this.canEdit = this.userService.hasAuthScope(this.user, [
        AdminScopes.MODIFY_USERS_SETTINGS_COURIER_MODIFIERS,
      ]);

      this.companyService
        .getCourierModifiers(parseInt(this.company.id))
        .subscribe(
          (courierModifiers) => {
            this.logger.log(
              'Configure Courier Modifiers - Get Courier Modifiers',
              courierModifiers
            );
            this.courierModifiers = courierModifiers.map((courierModifier) => {
              courierModifier.providerName =
                this.helpersService.convertShipmentProviderToString(
                  courierModifier.provider
                );
              if (courierModifier.lastMileProvider) {
                const lastMileProviderName =
                  this.helpersService.convertShipmentProviderToString(
                    courierModifier.lastMileProvider
                  );
                courierModifier.providerName = `${courierModifier.providerName} -> ${lastMileProviderName}`;
              }
              courierModifier.markups = '';
              if (courierModifier.markupPercentageValue) {
                courierModifier.markups = `${courierModifier.markupPercentageValue}%`;
              }
              if (courierModifier.markupDollarValue) {
                courierModifier.markups +=
                  courierModifier.markups.length > 0 ? ', ' : '';
                courierModifier.markups = `${courierModifier.markups}$${courierModifier.markupDollarValue}`;
              }
              courierModifier.deliveryDateDisplay = '';
              if (courierModifier.deliveryDate) {
                courierModifier.deliveryDateDisplay =
                  courierModifier.deliveryDate > 0
                    ? `+${courierModifier.deliveryDate}`
                    : `-${courierModifier.deliveryDate}`;
              }
              return courierModifier;
            });
            this.loading = false;
          },
          (err) => {
            this.courierModifiers = [];
            this.snackBar.open(
              `There was a problem getting the Courier Modifiers for this company. ${_.get(
                err,
                'error.message',
                ''
              )}`,
              '',
              { panelClass: 'error' }
            );
            this.logger.error(
              'Configure Courier Modifiers - Get Courier Modifiers',
              err
            );
            this.loading = false;
          }
        );
    } else {
      this.router.navigate(['users']);
    }
  }

  public addCourierModifier(): void {
    if (this.canEdit) {
      const drawerRef = this.drawer.open(UpdateCourierModifierComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { company: this.company },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.refreshCompany.emit();
          this.snackBar.open('Courier Modifier was saved', '', {
            panelClass: 'success',
          });
        }
      });
    }
  }

  public updateCourierModifier(courierModifier: any): void {
    if (this.canEdit) {
      const drawerRef = this.drawer.open(UpdateCourierModifierComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { company: this.company, courierModifier },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.refreshCompany.emit();
          this.snackBar.open('Courier Modifier was saved', '', {
            panelClass: 'success',
          });
        }
      });
    }
  }

  public deleteCourierModifier(courierModifier: any): void {
    if (this.canEdit) {
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: 'Delete modifier',
          content: `Are you sure you want to delete this modifier?`,
          confirmBtnLabel: 'DELETE modifier',
          btnColor: 'warn',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.companyService
            .deleteCourierModifier(courierModifier.id)
            .subscribe(() => {
              this.refreshCompany.emit();
              this.snackBar.open('Courier Modifier was deleted', '', {
                panelClass: 'success',
              });
            });
        }
      });
    }
  }
}
