import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { Component, Inject, OnInit } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  BillingInvoicesService,
  InvoiceBy,
} from '@app/shared/services/billing-invoices.services';
import { CacheService } from '@app/shared/services/cache.service';

@Component({
  selector: 'app-generate-invoice',
  templateUrl: './generate-invoice.component.html',
})
export class GenerateInvoiceComponent implements OnInit {
  public loading: boolean = true;
  public companies: Company[];
  public lastInvoiceNumber: number;
  public generateInvoiceForm: FormGroup;
  public defaultTimezone = 'America/Toronto';

  public id: number;
  public invoiceNumber: string;
  public trackingNumber: string;
  public dueDate: Date | string = DateTime.now().plus({ day: 7 }).toJSDate();
  public invoiceDate: Date = DateTime.now().startOf('day').toJSDate();
  public invoiceMethodValue: InvoiceBy = InvoiceBy.DATE_RANGE;
  public dateRange = {
    start: DateTime.now()
      .setZone(this.defaultTimezone)
      .minus({ day: 7 })
      .toJSDate(),
    end: DateTime.now()
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toJSDate(),
  };

  public range = new FormGroup({
    start: new FormControl<Date | null>(this.dateRange.start),
    end: new FormControl<Date | null>(this.dateRange.end),
  });
  public applyCreditCardFee: boolean = false;

  constructor(
    public drawerRef: MtxDrawerRef<GenerateInvoiceComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: { companies: Company[]; lastInvoiceNumber: number },
    private formBuilder: FormBuilder,
    private billingInvoicesService: BillingInvoicesService,
    private logger: LoggerService,
    private snackBar: MatSnackBar,
    private cacheService: CacheService
  ) {}
  ngOnInit() {
    this.loading = true;
    this.companies = this.data.companies;
    this.lastInvoiceNumber = this.data.lastInvoiceNumber;
    this.dueDate = DateTime.now().plus({ day: 7 }).toJSDate();
    this.setupForm();
    this.loading = false;
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public updateGenerateInvoiceForm(invoiceMethod: InvoiceBy): void {
    this.invoiceMethodValue = invoiceMethod;
    this.generateInvoiceForm
      .get('startDate')
      .setValidators([Validators.required]);
    this.generateInvoiceForm
      .get('endDate')
      .setValidators([Validators.required]);
    this.generateInvoiceForm
      .get('invoiceDate')
      .setValidators([Validators.required]);

    this.generateInvoiceForm.controls['startDate'].updateValueAndValidity();
    this.generateInvoiceForm.controls['endDate'].updateValueAndValidity();
    this.generateInvoiceForm.controls['invoiceDate'].updateValueAndValidity();
    this.loading = false;
  }

  public create(): void {
    this.loading = true;

    const startDate = DateTime.fromJSDate(this.range.value.start)
      .setZone(this.defaultTimezone)
      .startOf('day');
    const endDate = DateTime.fromJSDate(this.range.value.end)
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 });

    const queryData: any = {
      lastInvoiceNumber: this.lastInvoiceNumber,
      invoiceDate: DateTime.fromJSDate(this.invoiceDate).toISO(),
      startDate: startDate.toISO(),
      startDateUTC: startDate.toUTC().toISO(),
      endDate: endDate.toISO(),
      endDateUTC: endDate.toUTC().toISO(),
      isBatch: true,
      companies: JSON.stringify(this.companies),
    };

    this.billingInvoicesService.createInvoicePdf(queryData).subscribe(
      (response: any) => {
        this.logger.log('Generate invoices - Create PDF', response);
        this.loading = false;
        this.snackBar.open('Invoice creation job started successfully.', '', {
          panelClass: 'success',
        });
        this.drawerRef.dismiss('complete');
      },
      (err: any) => {
        if (err === 'Request timed out') {
          // Invoice creation is taking too long, so let user go while request processes
          this.loading = false;
          this.drawerRef.dismiss('processing');
        } else {
          this.logger.error('Billing invoices - Create Invoice error', err);
          this.loading = false;
        }
      }
    );
  }

  private setupForm() {
    this.generateInvoiceForm = this.formBuilder.group({
      startDate: [this.dateRange.start],
      endDate: [this.dateRange.end],
      invoiceDate: [this.invoiceDate],
    });
    this.updateGenerateInvoiceForm(this.invoiceMethodValue);
  }
}
