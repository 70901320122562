import { commonEnv } from './environment.common';

const env: Partial<typeof commonEnv> = {
  staging: true,
  APP_SERVICES_URL: 'https://api.cert.machool.com/REST-app-services/api/v1',
  INTEGRATION_SERVICES_URL: 'https://api.cert.machool.com/REST-integration-services/api/v1',
  UTILITY_SERVICES_URL: 'https://api.cert.machool.com/REST-utility-services/api/v1',
  ADMIN_PORTAL_URL: 'https://admin.cert.machool.com',
  firebaseTenantID: 'cert-admin-panel-xu1n2',
};

export const environment = Object.assign(commonEnv, env);
