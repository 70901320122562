import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { ProvidersService } from '@app/shared/services/providers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CourierSettingsComponent } from '@app/pages/authenticated/pages/couriers/components/courier-settings/courier-settings.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-edit-child-courier-settings',
  templateUrl: './edit-child-courier-settings.component.html',
})
export class EditChildCourierSettingsComponent implements OnInit {
  @ViewChild('childProviderForm', { static: false })
  courierSettingsComponent: CourierSettingsComponent;

  public loading: boolean = true;
  public provider: any;
  public childProviderSettingsForms: FormGroup;
  public childProviderSettingsFormArray: FormArray = new FormArray([]);
  public childProviders: any;
  public activeChildProviderIndex: number = 0;
  public childProviderTabs: any[] = [];
  public allFormsValid: boolean = true;
  public canEditStatus: boolean = false;

  constructor(
    public drawerRef: MtxDrawerRef<EditChildCourierSettingsComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: { provider: any, canEditStatus: boolean },
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private providersService: ProvidersService,
    private logger: LoggerService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    this.provider = this.data.provider;
    this.canEditStatus = this.data.canEditStatus;
    this.setupForms();
    this.loading = false;
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public updateSettings(): void {
    const updatedChildProviders = [];
    // Go through each set of child form values
    this.childProviderSettingsForms.value.childProviderSettings.forEach(
      (updatedProviderSettings: any, i) => {
        const childProvider = this.childProviderTabs[i].providerType;
        const markupPercentageValue =
          updatedProviderSettings?.markupPercentageValue || null;
        const markupDollarValue =
          updatedProviderSettings?.markupDollarValue || null;
        const deliveryDate = updatedProviderSettings?.deliveryDate || null;
        const enabled = updatedProviderSettings?.enabled !== undefined 
        ? updatedProviderSettings.enabled
        : null;

        // Append the update object
        updatedChildProviders.push({
          provider: childProvider,
          markupPercentageValue,
          markupDollarValue,
          deliveryDate,
          enabled,
        });
      }
    );
    const params = {
      provider: this.provider.providerType,
      category: this.provider.category,
      enabled: this.provider.enabled,
      childProviders: updatedChildProviders,
    };

    this.providersService.updateGlobalProvider(params).subscribe(
      (res) => {
        this.snackBar.open(
          'Global provider settings successfully updated',
          '',
          {
            panelClass: 'success',
          }
        );
        this.loading = false;
        this.drawerRef.dismiss(true);
      },
      (err) => {
        this.loading = false;
        this.snackBar.open(
          'Failed to update the global provider settings',
          '',
          {
            panelClass: 'error',
          }
        );
        this.logger.error('Updating global provider settings failed', err);
      }
    );
  }

  public getProviderFormGroup(index): FormGroup {
    return this.childProviderSettingsFormArray.controls[index] as FormGroup;
  }

  private setupForms(): void {
    // Setup a base form with an array for FormGroups
    this.childProviderSettingsForms = this.formBuilder.group({
      childProviderSettings: this.formBuilder.array([]),
    });
    // Setup a helper variable for easier access
    this.childProviderSettingsFormArray = this.childProviderSettingsForms.get(
      'childProviderSettings'
    ) as FormArray;

    this.provider.childProviders.forEach((childProvider) => {
      const providerMarkupMarginPercent = _.has(
        childProvider,
        'markupPercentageValue'
      )
        ? childProvider.markupPercentageValue
        : null;
      const providerMarkupMarginAmount = _.has(
        childProvider,
        'markupDollarValue'
      )
        ? childProvider.markupDollarValue
        : null;
      const providerDeliveryDate = _.has(childProvider, 'deliveryDate')
        ? childProvider.deliveryDate
        : null;
      const providerEnabled = _.has(childProvider, 'enabled')
        ? childProvider.enabled
        : null;

      // New FormGroup for each child provider
      const form = this.formBuilder.group({
        markupPercentageValue: [
          providerMarkupMarginPercent,
          [Validators.max(100), Validators.min(0)],
        ],
        markupDollarValue: [
          providerMarkupMarginAmount,
          [Validators.max(100000), Validators.min(0)],
        ],
        deliveryDate: [
          providerDeliveryDate,
          [Validators.max(10), Validators.min(1)],
        ],
        enabled: [
          providerEnabled
        ]
      });
      // Append the new FormGroup to the FormGroupArray
      this.childProviderSettingsFormArray.push(form);
      // Append the child provider to the tab array
      this.childProviderTabs.push(childProvider);
    });

    // Handle custom form validity logic on any value change
    this.childProviderSettingsForms.valueChanges.subscribe((valueChanged) => {
      const childProviderSettingsValueArray =
        valueChanged.childProviderSettings || [];
      let isAllFormsValid = this.childProviderSettingsForms.valid;
      let isNotAllFormsEmpty = false;
      for (const values of childProviderSettingsValueArray) {
        if (
          !Object.values(values).every(
            (value) => value === null || value === ''
          )
        ) {
          isNotAllFormsEmpty = true;
        }
      }
      this.allFormsValid = isNotAllFormsEmpty && isAllFormsValid;
      this.changeDetectorRef.detectChanges();
    });
  }
}
