import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  Company,
  CompanyInvoice,
} from '@app/pages/authenticated/pages/users/users.model';
import { DateFormats, PanelWidths } from '@app/shared/constants';
import {
  MTX_DRAWER_DATA,
  MtxDrawer,
  MtxDrawerRef,
} from '@ng-matero/extensions/drawer';
import { SortDirection } from '@angular/material/sort';
import { CreatePaymentComponent } from '@app/pages/authenticated/pages/invoices/components/create-payment/create-payment.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import * as _ from 'lodash';
import { BillingInvoicesService } from '@app/shared/services/billing-invoices.services';
import { LoggerService } from '@app/shared/services/logger.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-invoice-payments-list',
  templateUrl: './invoice-payments-list.component.html',
})
export class InvoicePaymentsListComponent implements OnInit {
  @Input() company: Company;
  @Input() invoice: CompanyInvoice;

  public loading: boolean = true;
  public invoicePaymentDataSet: any;
  public DATE_YEAR_SML: string = DateFormats.DATE_YEAR_SML;
  public displayedColumns = ['paymentDate', 'amount', 'description', 'action'];
  private openingDrawer: boolean = false;
  private openingModal: boolean = false;

  constructor(
    public drawerRef: MtxDrawerRef<InvoicePaymentsListComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: { company: Company; invoice: CompanyInvoice },
    private billingInvoicesService: BillingInvoicesService,
    private logger: LoggerService,
    private dialog: MatDialog,
    private drawer: MtxDrawer,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    this.company = this.data.company;
    this.invoice = this.data.invoice;
    (this.invoice?.invoicePayments || []).forEach((payment) => {
      payment.formattedDate = DateTime.fromISO(payment.paymentDate, {
        zone: 'America/Toronto',
      }).toFormat(DateFormats.DATE_YEAR_SML);
    });
    this.invoicePaymentDataSet = (this.invoice?.invoicePayments || []).sort(
      (a, b) => (a.paymentDate < b.paymentDate ? -1 : 1)
    );
    this.loading = false;
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public addInvoicePayment(invoice: CompanyInvoice): void {
    this.drawerRef.dismiss({ addPayment: invoice });
  }

  public deleteInvoicePayment(invoicePayment: any): void {
    if (!this.openingModal) {
      this.openingModal = true;
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: `Delete invoice payment`,
          content: `Are you sure you want to delete this payment for: $${invoicePayment.amount}?`,
          confirmBtnLabel: 'Delete',
          btnColor: 'warn',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.billingInvoicesService
            .deleteInvoicePayment(invoicePayment.id)
            .subscribe(
              () => {
                this.drawerRef.dismiss(true);
                this.snackBar.open('Invoice payment was deleted', '', {
                  panelClass: 'success',
                });
              },
              (err: Error) => {
                this.logger.error(
                  'Invoices - DELETE invoice payment error',
                  err
                );
                this.snackBar.open(
                  `There was a problem deleting this invoice payment${_.get(
                    err,
                    'error.message',
                    ''
                  )}`,
                  '',
                  { panelClass: 'error' }
                );
              }
            );
        }
        this.openingModal = false;
      });
    }
  }
}
