import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import * as _ from 'lodash';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { CompanyService } from '@app/shared/services/company.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShippingProviderNames, ShippingProviders, } from '@app/shared/constants';
import { AdminScopes, AdminUser, UserService, } from '@app/shared/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pickup',
  templateUrl: './pickup.component.html',
})
export class PickupComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  public loading = true;
  public canEdit: boolean = false;

  public machoolPickupForm: FormGroup;
  public selectedMachoolPickupProviders: string[] = [];
  public machoolPickupProviders: {
    name: string;
    circleClass: string;
    selected: boolean;
    pickupFeeField: string;
  }[] = [
    {
      name: ShippingProviderNames.AIR_CANADA_DOMESTIC,
      circleClass: 'AirCanadaDomestic',
      selected: false,
      pickupFeeField: 'machoolPickupFee'
    },
    {
      name: ShippingProviderNames.AIR_CANADA_INTERNATIONAL,
      circleClass: 'AirCanadaInternational',
      selected: false,
      pickupFeeField: 'machoolInternationalPickupFee'
    },
    {
      name: ShippingProviderNames.UNIUNI,
      circleClass: ShippingProviderNames.UNIUNI,
      selected: false,
      pickupFeeField: 'machoolUniUniPickupFee'
    },
    {
      name: ShippingProviderNames.SPRING,
      circleClass: ShippingProviderNames.SPRING,
      selected: false,
      pickupFeeField: 'machoolSpringPickupFee'
    },
    {
      name: ShippingProviderNames.ULALA,
      circleClass: ShippingProviderNames.ULALA,
      selected: false,
      pickupFeeField: 'machoolUlalaPickupFee'
    },
  ];
  public machoolPickupFee: number | undefined;
  public machoolInternationalPickupFee: number | undefined;
  public machoolUniUniPickupFee: number | undefined;
  public machoolSpringPickupFee: number | undefined;
  public machoolUlalaPickupFee: number | undefined;
  public showMachoolPickupFeeWarning: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private userService: UserService,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}
  ngOnInit() {
    this.loading = true;
    if (
      this.company &&
      this.user &&
      this.userService.hasAuthScope(this.user, [
        AdminScopes.VIEW_USERS_SETTINGS_MACHOOL_PICKUP,
      ])
    ) {
      this.canEdit = this.userService.hasAuthScope(this.user, [
        AdminScopes.MODIFY_USERS_SETTINGS_MACHOOL_PICKUP,
      ]);

      const existingMachoolPickupProviders =
        _.get(this.company, 'settings.machoolPickupProviders') || [];
      this.selectedMachoolPickupProviders =
        existingMachoolPickupProviders || [];

      this.machoolPickupFee = _.get(
        this.company,
        'machoolPickupFee',
        undefined
      );

      this.machoolInternationalPickupFee = _.get(this.company, 'machoolInternationalPickupFee');

      this.machoolUniUniPickupFee = _.get(
        this.company,
        'machoolUniUniPickupFee',
        undefined
      );

      this.machoolSpringPickupFee = _.get(
        this.company,
        'machoolSpringPickupFee',
        undefined
      );

      this.machoolUlalaPickupFee = _.get(
        this.company,
        'machoolUlalaPickupFee',
        undefined
      );

      // Initialize pickup options for Machool pickup
      const machoolPickupProviderFormControls: FormControl[] = [];
      this.machoolPickupProviders.forEach(
        (machoolPickupProvider, index: number) => {
          if (
            existingMachoolPickupProviders &&
            (existingMachoolPickupProviders || []).length > 0
          ) {
            existingMachoolPickupProviders.forEach(
              (existingMachoolPickupProvider) => {
                // Load existing selected Machool pickup providers to the form
                // Air Canada Cargo Domestic
                if ([ShippingProviders.RIVO, ShippingProviders.AIR_CANADA_DOMESTIC].includes((existingMachoolPickupProvider || '').toLowerCase())) {
                  existingMachoolPickupProvider = ShippingProviderNames.AIR_CANADA_DOMESTIC;
                }

                // Air Canada Cargo International
                if ((existingMachoolPickupProvider || '').toLowerCase().includes(ShippingProviders.AIR_CANADA_INTERNATIONAL)) {
                  existingMachoolPickupProvider = ShippingProviderNames.AIR_CANADA_INTERNATIONAL;
                }

                if (
                  existingMachoolPickupProvider &&
                  (existingMachoolPickupProvider || '')
                    .toLowerCase()
                    .includes((machoolPickupProvider.name || '').toLowerCase())
                ) {
                  this.machoolPickupProviders[index].selected = true;
                }
              }
            );
          }

          machoolPickupProviderFormControls.push(
            new FormControl({
              value: machoolPickupProvider.name,
              disabled: !this.canEdit,
            })
          );
        }
      );

      this.machoolPickupForm = this.formBuilder.group({
        machoolPickupProviders: new FormArray(
          machoolPickupProviderFormControls
        ),
        machoolPickupFee: [
          { value: this.machoolPickupFee, disabled: !this.canEdit },
          [Validators.required, Validators.max(99), Validators.min(0)],
        ],
        machoolInternationalPickupFee: [
          { value: this.machoolInternationalPickupFee, disabled: !this.canEdit },
          [Validators.required, Validators.max(99), Validators.min(0)],
        ],
        machoolUniUniPickupFee: [
          { value: this.machoolUniUniPickupFee, disabled: !this.canEdit },
          [Validators.required, Validators.max(99), Validators.min(0)],
        ],
        machoolSpringPickupFee: [
          { value: this.machoolSpringPickupFee, disabled: !this.canEdit },
          [Validators.required, Validators.max(99), Validators.min(0)],
        ],
        machoolUlalaPickupFee: [
          { value: this.machoolUlalaPickupFee, disabled: !this.canEdit },
          [Validators.required, Validators.max(99), Validators.min(0)],
        ],
      });
      this.showMachoolPickupFeeWarning =
        _.isNil(this.machoolPickupFee) ||
        _.isNil(this.machoolInternationalPickupFee) ||
        _.isNil(this.machoolUniUniPickupFee) ||
        _.isNil(this.machoolSpringPickupFee) ||
        _.isNil(this.machoolUlalaPickupFee);
      this.loading = false;
    } else {
      this.router.navigate(['users']);
    }
  }

  public selectMachoolPickupProvider(event: any): void {
    let eventValue: any = _.get(event, 'source.value');

    // Ensure to convert back to:
    // + Rivo for AC domestic e.g: AC-CP or AC-UniUni etc.
    // + Air Canada Cargo (Multi-courier-international) for AC international e.g: AC-Spring
    // TODO: AC - Revisit when we completely re-brand to AC
    if ([ShippingProviderNames.AIR_CANADA,
      ShippingProviderNames.AIR_CANADA_DOMESTIC].includes(eventValue || '')) {
      eventValue = ShippingProviderNames.RIVO;
    }

    if (event.checked) {
      this.selectedMachoolPickupProviders.push(eventValue);
    } else {
      _.remove(
        this.selectedMachoolPickupProviders,
        (provider: string) =>
          provider &&
          eventValue &&
          (provider || '')
            .toLowerCase()
            .includes((eventValue || '').toLowerCase())
      );
    }
  }

  public updatePickups(): void {
    if (this.canEdit) {
      // TODO: AC - Revisit when we completely re-brand from RIVO to AC
      if (!_.isEmpty(this.selectedMachoolPickupProviders || [])) {
        this.selectedMachoolPickupProviders =
          this.selectedMachoolPickupProviders.map((machoolPickupProvider) => {
            // Air Canada Cargo Domestic
            if ([ShippingProviderNames.AIR_CANADA,
              ShippingProviderNames.AIR_CANADA_DOMESTIC].includes(machoolPickupProvider as ShippingProviderNames)) {
              return ShippingProviderNames.RIVO;

              // Others
            } else {
              return machoolPickupProvider;
            }
          });
      }
      this.selectedMachoolPickupProviders = _.uniq(
        this.selectedMachoolPickupProviders
      );
      const updateCompanySettings = {
        machoolPickupFee: this.machoolPickupForm.get('machoolPickupFee').value, // For AC domestic shipments pickup fee
        machoolInternationalPickupFee: this.machoolPickupForm.get('machoolInternationalPickupFee').value, // For AC international shipments pickup fee
        machoolUniUniPickupFee: this.machoolPickupForm.get('machoolUniUniPickupFee').value, // For UniUni shipments pickup fee
        machoolSpringPickupFee: this.machoolPickupForm.get('machoolSpringPickupFee').value, // For Spring international shipments pickup fee
        machoolUlalaPickupFee: this.machoolPickupForm.get('machoolUlalaPickupFee').value, // For Ulala shipments pickup fee
        machoolPickupProviders: this.selectedMachoolPickupProviders,
      };

      const updateObj = {
        settings: _.extend(this.company.settings, updateCompanySettings),
      };

      this.companyService.update(this.company.uuid, updateObj).subscribe(
        (response: any) => {
          this.refreshCompany.emit();
          this.logger.log(
            'Modals Machool Pickup - UPDATE Machool pickup providers',
            response
          );
          this.snackBar.open('Machool pickup providers has been updated', '', {
            panelClass: 'success',
          });
        },
        (err: Error) => {
          this.snackBar.open(
            `There was a problem updating the Machool pickup provider. ${_.get(
              err,
              'error.message',
              ''
            )}`,
            '',
            { panelClass: 'error' }
          );
          this.logger.error(
            'Modals Machool Pickup - UPDATE Machool pickup error',
            err
          );
        }
      );
    }
  }
}
