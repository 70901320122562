import { Component, Inject, OnInit } from '@angular/core';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import {
  Dashboard,
  DashboardService,
} from '@app/shared/services/dashboard.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProvinceList } from '@app/shared/constants';

@Component({
  selector: 'app-add-dashboard',
  templateUrl: './add-dashboard.component.html',
})
export class AddDashboardComponent implements OnInit {
  public loading: boolean = true;
  public dashboardForm: FormGroup;
  private readonly provinces = ProvinceList;
  public provincesDropdown;

  constructor(
    public drawerRef: MtxDrawerRef<AddDashboardComponent>,
    @Inject(MTX_DRAWER_DATA) public data: { dashboards: Dashboard[] },
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private logger: LoggerService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    const takenProvinces: string[] =
      this.data.dashboards && this.data.dashboards.length > 0
        ? this.data.dashboards.map((dashboard) => dashboard.province)
        : [];
    this.provincesDropdown = this.provinces.filter(
      (province) => !takenProvinces.includes(province.id)
    );
    this.setupForm();
  }

  public dismissDrawer(success: boolean = false): void {
    this.drawerRef.dismiss(success);
  }

  public saveDashboard(): void {
    if (!this.dashboardForm.invalid) {
      const createDashboardDto: any = this.dashboardForm.value;
      this.dashboardService.createDashboard(createDashboardDto).subscribe(
        (res) => {
          this.snackBar.open('Dashboard was saved successfully', '', {
            panelClass: 'success',
          });
          this.logger.log('Create dashboard success', res);
          this.dismissDrawer(true);
        },
        (err) => {
          this.snackBar.open('Saving the Dashboard failed', '', {
            panelClass: 'error',
          });
          this.logger.error('Create dashboard error', err);
          this.dismissDrawer();
        }
      );
    }
  }

  private setupForm() {
    this.dashboardForm = this.formBuilder.group({
      province: ['', [Validators.required]],
    });
    this.loading = false;
  }
}
