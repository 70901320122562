import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LoggerService } from '@app/shared/services/logger.service';
import { SessionStorageService } from 'ngx-webstorage';
import { AdminUser } from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CacheKeys, DateFormats, Mailers, PUBLIC_INVOICES_URL_PATH, PanelWidths } from '@app/shared/constants';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { InvoicesService } from '@app/shared/services/invoices.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { CompanyInvoice } from '../../../users/users.model';
import { MatDialog } from '@angular/material/dialog';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { BillingInvoicesService } from '@app/shared/services/billing-invoices.services';
import { GenerateInvoiceComponent } from '../generate-invoice/generate-invoice.component';
import { MailerService } from '@app/shared/services/mailer.service';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { CreateInvoiceComponent } from '../create-invoice/create-invoice.component';
import { CacheService } from '@app/shared/services/cache.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../../../../../../environments/environment';

export interface PeriodicElement {
  id: number;
  company: string;
  balance: string;
  limit: string;
  due: string;
  lastInvoice: string;
  lastPayment: string;
  lastEmailSent: string;
}

@Component({
  selector: 'app-customer-balance-invoices',
  templateUrl: './customer-balance-invoices.component.html',
  styleUrls: ['./customer-balance-invoices.component.scss'],
})
export class CustomerBalanceInvoicesComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @Input() user: AdminUser;
  @Input() canViewCustomerBalances: boolean = false;
  @Input() canGenerateInvoicesForCustomers: boolean = false;
  @Input() canEditAndEmailInvoices: boolean = false;
  @Input() invoicesGenerated: boolean;
  @Output() goToSecondTab: EventEmitter<any> = new EventEmitter<any>();

  public loading: boolean = true;
  private openingDrawer: boolean = false;
  public allCustomerBalances: any;
  public companies: [];
  public defaultTimezone = 'America/Toronto';
  public DATE_YEAR: string = DateFormats.DATE_YEAR;
  public DATE_YEAR_SML: string = DateFormats.DATE_YEAR_SML;
  public DATE_MONTH: string = DateFormats.DATE_MONTH_SML;
  public itemsPerPage = 10;
  public totalItems: number;
  public orderByValue: string = 'billingAmount';
  public currentPage: number = 0;
  public sortAscending: SortDirection = 'desc';
  private openingModal: boolean = false;
  public company;
  public paginatedCompanies = [];
  private lastInvoiceNumber;
  public shouldSendEmail: boolean = false;
  public lastCompanyInvoice;
  public companiesDataSet: any;
  public filterForm: FormGroup | undefined;

  public columnsToDisplay: string[] = [
    'select',
    'name',
    'billingAmount',
    'limit',
    'due',
    'lastInvoice',
    'lastPayment',
    'action',
  ];
  selection = new SelectionModel<PeriodicElement>(true, []);

  constructor(
    private logger: LoggerService,
    private formBuilder: FormBuilder,
    private sessionStorageService: SessionStorageService,
    private invoicesService: InvoicesService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private drawer: MtxDrawer,
    private billingInvoicesService: BillingInvoicesService,
    private mailerService: MailerService,
    private cacheService: CacheService
  ) {}
  ngOnInit() {
    this.loading = true;
    if (this.canViewCustomerBalances) {
      this.createForm();
      this.getAllCustomerBalances();
      this.getLastInvoiceNumber();
    }
  }

  private createForm() {
    this.filterForm = this.formBuilder.group({
      companyName: ['', []],
    });
  }

  public setOrder(order: Sort): void {
    this.sortAscending =
      order.active === this.orderByValue
        ? order.direction === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';

    this.orderByValue = order.active;
    this.getAllCustomerBalances();
    this.getLastInvoiceNumber();
  }

  public search() {
    this.loading = true;
    this.getAllCustomerBalances(this.filterForm.value.companyName);
  }

  public reset() {
    this.loading = true;
    this.getAllCustomerBalances();
  }

  public getAllCustomerBalances(companyName?: string): any {
    const query = {
      order_by_value: this.orderByValue,
      order_by_direction: this.sortAscending.toUpperCase(),
    };
    if (companyName && companyName.length > 0) {
      _.extend(query, { company_name: companyName.trim() });
    }

    this.invoicesService.getAllCustomerBalancesData(query).subscribe(
      (res) => {
        this.logger.log('GET all customer invoices', res);
        this.companies = res.companies;
        this.totalItems = res.companiesCount;
        this.paginatedCompanies = res.companies;
        this.companies.map((company: any) => {
          company.hasInvoices = company.invoices && company.invoices.length > 0;
        });

        this.companiesDataSet = new MatTableDataSource(this.companies);
        this.companiesDataSet.sort = this.sort;

        this.loading = false;
      },
      (err: Error) => {
        this.loading = false;
        this.logger.error('GET all customer invoices error', err);
      }
    );
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this?.companies?.length;
    return numSelected === numRows;
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.companies);
  }

  public checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id
    }`;
  }

  public getBillingAmountClass(row: any): string {
    const amount = Number(row.billingAmount);
    const limit = Number(row.billingLimit);

    if (amount > limit) {
      return 'text-danger';
    } else if (amount < limit && amount > limit * 0.8) {
      return 'text-warn';
    } else {
      return '';
    }
  }

  // Redirect to invoices tab for selected company (invoice history)
  public viewInvoices(selectedInvoice: CompanyInvoice): void {
    if (selectedInvoice) {
      this.goToSecondTab.emit(selectedInvoice);
    }
  }

  public goToCustomerSettings(company: any) {
    if (company) {
      this.sessionStorageService.store('settingsCompany', company);
      this.router.navigate(['users/settings']);
    }
  }

  public openGenerateInvoiceAllModal(
    companies: SelectionModel<PeriodicElement>
  ): void {
    this.generateInvoiceAll(this.selection.selected);
  }

  public emailLatestInvoice(company: any): void {
    if (!company?.invoices || company?.invoices?.length === 0) {
      return;
    }

    // We only return the latest company invoice
    const latestCompanyInvoice = company.invoices[0];
    const companyLanguage = company?.language || 'en';
    const merchantEmail = company?.settings?.billingEmail
      ? company?.settings?.billingEmail
      : company?.owner?.email || '';
    const merchantFirstName = company?.settings?.firstName
      ? company?.settings?.firstName
      : company?.owner?.firstName || '';

    this.getBillingInvoiceData(latestCompanyInvoice).then((data) => {
      const templateData = this.createEmailTemplate(
        latestCompanyInvoice,
        merchantFirstName,
        data
      );
      this.showConfirmationDialog(
        company.name,
        latestCompanyInvoice.invoiceNumber,
        () => {
          return this.sendInvoiceEmail(
            merchantEmail,
            templateData,
            companyLanguage
          );
        }
      );
    });
  }

  private getBillingInvoiceData(
    latestCompanyInvoice: CompanyInvoice
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.billingInvoicesService
        .getStatementsDetails({
          invoiceNumber: latestCompanyInvoice.invoiceNumber,
        })
        .subscribe(
          (result) => {
            this.logger.log('Customer balance invoices - GET PDF', result);
            resolve(result);
          },
          (error) => {
            this.logger.error('Customer balance invoices - GET PDF', error);
            reject(error);
          }
        );
    });
  }

  private createEmailTemplate(
    latestCompanyInvoice: CompanyInvoice,
    merchantFirstName: string,
    data: any
  ): any {
    return {
      invoice_number: latestCompanyInvoice.invoiceNumber,
      invoice_pdf: data?.pdfStatement?.url ? `${environment.ADMIN_PORTAL_URL}/${PUBLIC_INVOICES_URL_PATH}/pdf/${data.pdfStatement?.url}` : '',
      invoice_csv: data.csvStatement?.url ? `${environment.ADMIN_PORTAL_URL}/${PUBLIC_INVOICES_URL_PATH}/csv/${data.csvStatement?.url}` : '',
      company_first_name: merchantFirstName,
      pdfAttachment: data?.pdfSummary
        ? data.pdfSummary?.data[0]
        : data?.data
        ? data?.data[0]
        : '',
      filename: `Machool - Invoice Summary ${latestCompanyInvoice.invoiceNumber}.pdf`,
      emailDateSent: DateTime.now().toISO(),
      additionalData: { invoiceId: latestCompanyInvoice.id, isBatch: false },
    };
  }

  private showConfirmationDialog(
    companyName: string,
    invoiceNumber: string,
    callback: () => void
  ): void {
    if (!this.openingModal) {
      this.openingModal = true;
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: `Send invoice to ${companyName}`,
          content: `Are you sure you want to send invoice ${invoiceNumber}?`,
          confirmBtnLabel: 'Send email',
          btnColor: 'primary',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          callback();
        }
        this.openingModal = false;
      });
    }
  }

  private sendInvoiceEmail(
    merchantEmail: string,
    templateData: any,
    language: string
  ): void {
    this.mailerService
      .send(
        `${Mailers.SEND_CUSTOMER_INVOICE}.${language.toUpperCase() || 'EN'}`,
        templateData,
        merchantEmail
      )
      .subscribe(
        (response) => {
          this.loading = false;
          this.logger.log('Customers - Invoice email send', response);
          this.snackBar.open('Email Send ...', '', { panelClass: 'success' });
          this.selection.clear();
        },
        (error) => {
          this.loading = false;
          this.logger.error('Customers - Invoice email send error', error);
          this.snackBar.open('Problem Sending latest invoice', '', {
            panelClass: 'error',
          });
          this.selection.clear();
        }
      );
  }

  public createSingleInvoice(company: any): void {
    if (!this.openingDrawer) {
      const drawerRef = this.drawer.open(CreateInvoiceComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { company, lastInvoiceNumber: this.lastInvoiceNumber },
      });
    }
  }

  public generateInvoiceAll(companies: any): void {
    if (!this.openingDrawer) {
      const drawerRef = this.drawer.open(GenerateInvoiceComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { companies, lastInvoiceNumber: this.lastInvoiceNumber },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.snackBar.open('Invoice creation job started successfully.', '', {
            panelClass: 'success',
          });
          this.getAllCustomerBalances();
          this.selection.clear();
        }
      });
    }
  }

  public getLastInvoiceNumber(): any {
    this.cacheService
      .getCache(CacheKeys.INVOICES_LAST_INVOICE_NUMBER)
      .subscribe(
        (res) => {
          this.logger.info('find the last invoiceNumber from redis', res);
          if (res) {
            this.lastInvoiceNumber = parseInt(res);
          }
        },
        (error) => {
          this.logger.error(
            'Not able to find the last invoiceNumber from redis',
            error
          );
        }
      );
  }
}
