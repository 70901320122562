import { Component, Inject, OnInit } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from '@app/shared/services/company.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CourierModifierDTO,
  GlobalChildProviderInterface,
  GlobalCourierRO,
  ProvidersService,
} from '@app/shared/services/providers.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-update-courier-modifier',
  templateUrl: './update-courier-modifier.component.html',
})
export class UpdateCourierModifierComponent implements OnInit {
  public isEditing: boolean;
  public loading: boolean = true;
  public company: Company;
  public courierModifier: CourierModifierDTO;
  public couriersList: GlobalCourierRO[] = [];
  public selectedCourier: GlobalCourierRO;
  public lastMileProviders: GlobalChildProviderInterface[];
  public courierModifierForm: FormGroup;

  constructor(
    public drawerRef: MtxDrawerRef<UpdateCourierModifierComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: { company: Company; courierModifier?: CourierModifierDTO },
    private formBuilder: FormBuilder,
    private providersService: ProvidersService,
    private companyService: CompanyService,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    this.company = this.data.company;
    this.courierModifier = this.data.courierModifier;
    this.isEditing =
      this.courierModifier && this.courierModifier.id ? true : false;

    this.providersService.getEnabledGlobalProviders().subscribe(
      (res) => {
        const globalProviders: GlobalCourierRO[] = res.globalProviders;
        globalProviders.forEach((globalProvider) => {
          _.extend(globalProvider, {
            name: this.helpersService.convertShipmentProviderToString(
              globalProvider.provider
            ),
          });
          if (
            globalProvider.childProviders &&
            globalProvider.childProviders.length > 0
          ) {
            globalProvider.childProviders.forEach((childProvider) => {
              _.extend(childProvider, {
                name: this.helpersService.convertShipmentProviderToString(
                  childProvider.provider
                ),
              });
            });
          }
        });
        globalProviders.sort((providerA, providerB) => {
          if (providerA.name > providerB.name) return 1;
          if (providerA.name < providerB.name) return -1;
          return 0;
        });
        this.couriersList = globalProviders;
        this.setupForm();
        this.loading = false;
      },
      (error) => {
        this.logger.error(
          'Error Company Courier Modifiers - GET Global Providers',
          error
        );
        this.snackBar.open('Problem retrieving global providers', '', {
          panelClass: 'error',
        });
        this.dismissDrawer();
      }
    );
  }

  public selectCourier(selectedCourier: any): void {
    const selectedCourierValue = _.get(selectedCourier, 'value');
    this.lastMileProviders = [];
    if (selectedCourierValue) {
      this.selectedCourier = this.couriersList.find(
        (courier) => courier.provider === selectedCourierValue
      );
      this.selectLastMileCourier();
    }
  }

  private selectLastMileCourier(): void {
    this.lastMileProviders = [];
    if (this.selectedCourier) {
      this.lastMileProviders = _.get(
        this.selectedCourier,
        'childProviders',
        []
      );
    }
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public saveModifier(): void {
    if (this.isEditing) {
      this.update();
    } else {
      this.create();
    }
  }

  private update() {
    this.loading = true;
    this.companyService
      .updateCourierModifier(
        this.courierModifier.id,
        this.courierModifierForm.value
      )
      .subscribe(
        (courierModifier) => {
          this.logger.log(
            'Company Courier Modifiers - UPDATE Courier Modifier',
            courierModifier
          );
          this.loading = false;
          this.drawerRef.dismiss(true);
        },
        (error) => {
          this.logger.error(
            'Company Courier Modifiers - UPDATE Courier Modifier',
            error
          );
          this.snackBar.open('Problem saving courier modifier', '', {
            panelClass: 'error',
          });
          this.dismissDrawer();
        }
      );
    this.loading = false;
    this.drawerRef.dismiss(true);
  }

  private create() {
    this.loading = true;
    const params = this.courierModifierForm.value;
    this.companyService
      .createCourierModifier(parseInt(this.company.id), params)
      .subscribe(
        (courierModifier) => {
          this.logger.log(
            'Company Courier Modifiers - CREATE Courier Modifier',
            courierModifier
          );
          this.loading = false;
          this.drawerRef.dismiss(true);
        },
        (error) => {
          this.logger.error(
            'Company Courier Modifiers - CREATE Courier Modifier',
            error
          );
          this.snackBar.open('Problem saving courier modifier', '', {
            panelClass: 'error',
          });
          this.dismissDrawer();
        }
      );
    this.loading = false;
    this.drawerRef.dismiss(true);
  }

  public keyPressed($event) {
    if ($event.target.value.length === 0) {
      this.courierModifierForm.controls['markupDollarValue'].enable();
      this.courierModifierForm.controls['markupPercentageValue'].enable();
    }
    if (
      $event.target &&
      $event.target.id === 'markupPercentageValue' &&
      $event.target.value.length > 0
    ) {
      this.courierModifierForm.controls['markupDollarValue'].disable();
    }
    if (
      $event.target &&
      $event.target.id === 'markupDollarValue' &&
      $event.target.value.length > 0
    ) {
      this.courierModifierForm.controls['markupPercentageValue'].disable();
    }
  }

  private setupForm() {
    this.courierModifierForm = this.formBuilder.group({
      provider: [
        _.get(this.courierModifier, 'provider', null),
        [Validators.required],
      ],
      lastMileProvider: [_.get(this.courierModifier, 'lastMileProvider', null)],
      markupPercentageValue: [
        _.get(this.courierModifier, 'markupPercentageValue', null),
      ],
      markupDollarValue: [
        _.get(this.courierModifier, 'markupDollarValue', null),
      ],
      deliveryDate: [_.get(this.courierModifier, 'deliveryDate', null)],
    });
    if (this.courierModifier) {
      this.selectedCourier = this.couriersList.find(
        (courier) => courier.provider === this.courierModifier.provider
      );
      this.selectLastMileCourier();
    }
    this.disableMarkupField();
  }

  private disableMarkupField(): void {
    const markupDollarValue =
      this.courierModifierForm.get('markupDollarValue').value;
    const markupPercentageValue = this.courierModifierForm.get(
      'markupPercentageValue'
    ).value;
    if (markupDollarValue) {
      this.courierModifierForm.get('markupDollarValue').enable();
      this.courierModifierForm.get('markupPercentageValue').disable();
    }
    if (markupPercentageValue) {
      this.courierModifierForm.get('markupPercentageValue').enable();
      this.courierModifierForm.get('markupDollarValue').disable();
    }
  }
}
