import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import * as _ from 'lodash';
import { PricingTiers, PricingTierVerbose } from '@app/shared/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from '@app/shared/services/company.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tier',
  templateUrl: './tier.component.html',
})
export class TierComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  public tierForm: FormGroup;
  public selectedPricingTier: string;
  public currentCustomMargin: number = 15; // default selected value
  public pricingTiers: { tier: PricingTiers, tierName: PricingTierVerbose, margin: number[] }[] = [
    {
      tier: PricingTiers.BRONZE,
      tierName: this.helpersService.convertPricingTiersEnumToString(PricingTiers.BRONZE),
      margin: [ 30 ],
    },
    {
      tier: PricingTiers.SILVER,
      tierName: this.helpersService.convertPricingTiersEnumToString(PricingTiers.SILVER),
      margin: [ 25 ],
    },
    {
      tier: PricingTiers.GOLD,
      tierName: this.helpersService.convertPricingTiersEnumToString(PricingTiers.GOLD),
      margin: [ 20 ],
    },
    {
      tier: PricingTiers.AFFINITY_PANIER,
      tierName: this.helpersService.convertPricingTiersEnumToString(PricingTiers.AFFINITY_PANIER),
      margin: [ 19 ],
    },
    {
      tier: PricingTiers.AFFINITY_CQCD,
      tierName: this.helpersService.convertPricingTiersEnumToString(PricingTiers.AFFINITY_CQCD),
      margin: [ 17 ],
    },
    {
      tier: PricingTiers.EMPLOYEE,
      tierName: this.helpersService.convertPricingTiersEnumToString(PricingTiers.EMPLOYEE),
      margin: [ 0 ],
    },
    {
      tier: PricingTiers.CORPORATE,
      tierName: this.helpersService.convertPricingTiersEnumToString(PricingTiers.CORPORATE),
      margin: _.range(15, 40),
    },
  ];
  public canEdit:boolean = false;
  public loading = true;

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private userService: UserService,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
  }
  ngOnInit() {
    this.loading = true;
    if (this.company && this.user && this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS_SETTINGS_TIER])) {
      this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_USERS_SETTINGS_TIER]);
      this.selectedPricingTier = this.company.pricingTier;
      this.currentCustomMargin = this.company.pricingTier === PricingTiers.CORPORATE ? this.company.pricingMargin : this.currentCustomMargin;
      this.createForm();
      this.loading = false;
    } else {
      this.router.navigate(['users']);
    }
  }

  public selectPricingTier(pricingTier: PricingTiers) {
    if (this.canEdit) {
      this.selectedPricingTier = pricingTier;
      this.tierForm.patchValue({ pricingTier: this.selectedPricingTier });
      if (this.selectedPricingTier === PricingTiers.CORPORATE) {
        this.tierForm.get('pricingCustom').enable();
      } else {
        this.tierForm.get('pricingCustom').disable();
      }
      this.tierForm.updateValueAndValidity();
    }
  }

  public updatePricingTier() {
    if (this.canEdit) {
      const updateObj = {
        pricingTier: this.selectedPricingTier,
      };

      if (this.selectedPricingTier === PricingTiers.CORPORATE) {
        _.assign(updateObj, { pricingCustom: + this.tierForm.get('pricingCustom').value });
      }
      this.companyService
        .update(this.company.uuid, updateObj)
        .subscribe((response: any) => {
          this.logger.log('Modals accounts - UPDATE Pricing Tier', response);
          this.refreshCompany.emit();
          this.snackBar.open('Pricing Tier has been updated', '', { panelClass: 'success' });
        }, (err: Error) => {
          this.snackBar.open(`There was a problem updating the pricing tier. ${_.get(err, 'error.message', '')}`, '', { panelClass: 'error' });
          this.logger.error('Modals Pricing Tier - UPDATE Pricing Tier error', err);
        });
    }
  }

  private createForm() {
    this.tierForm = this.formBuilder.group({
      pricingTier: [{ value: this.selectedPricingTier, disabled: !this.canEdit }],
      pricingCustom: [
        { value: this.currentCustomMargin, disabled: !this.canEdit || this.selectedPricingTier !== PricingTiers.CORPORATE },
        [Validators.required]
      ],
    });
  }
}
