import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from '@app/shared/services/company.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { first } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
})
export class SalesComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  public loading = true;
  public salesReps = [];
  public salesRepsSelect = [];
  public salesRepForm: FormGroup;
  public selectedSalesRep = '';
  public salesRepDescription = '';
  public canEdit: boolean = false;

  constructor(
    private companyService: CompanyService,
    private helpersService: HelpersService,
    private userService: UserService,
    private logger: LoggerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
  ) { }
  ngOnInit() {
    this.loading = true;
    if (this.company && this.user && this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS_SETTINGS_SALES])) {
      this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_USERS_SETTINGS_SALES]);
      this.selectedSalesRep = `${this.company.salesRep.id}`;
      this.companyService.getSalesReps().subscribe((salesReps) => {
        this.logger.log('Configure Sales - Get Sales Reps', salesReps);
        this.salesReps = salesReps;
        this.salesRepsSelect = salesReps.map(salesRep => {
          return {
            id: salesRep.id,
            text: `${salesRep.firstName} ${salesRep.lastName}`,
            firstName: salesRep.firstName,
            lastName: salesRep.lastName,
            email: salesRep.email,
            description: salesRep.description,
          }
        });
        this.salesRepDescription = this.company.salesRep ? this.company.salesRep.description : '';
        this.createForm();
      });
    } else {
      this.router.navigate(['users']);
    }
  }

  public changeRepDescription() {
    const selectedSalesRep = this.getSelectedSalesRep();
    this.salesRepDescription = selectedSalesRep ? selectedSalesRep.description : '';
  }

  public updateCompany() {
    if (this.canEdit) {
      const selectedSalesRep = this.getSelectedSalesRep();
      this.companyService.update(this.company.uuid, { salesRep: selectedSalesRep }).subscribe((response: any) => {
        this.logger.log('Configure Sales - UPDATE Sales', response);
        this.refreshCompany.emit();
        this.snackBar.open('Sales updated', '', { panelClass: 'success' });
      }, (err: Error) => {
        this.snackBar.open(`There was a problem updating Sales for this company. ${_.get(err, 'error.message', '')}`, '', { panelClass: 'error' });
        this.logger.error('Configure Sales - UPDATE Sales error', err);
      });
    }
  }

  private getSelectedSalesRep(): any {
    return this.salesReps.find((rep) => rep.id === Number(this.selectedSalesRep || 0));
  }

  private createForm() {
    this.salesRepForm = this.formBuilder.group({
      salesRep: [{ value: this.selectedSalesRep, disabled: !this.canEdit }, Validators.required]
    });
    this.loading = false;
  }
}
