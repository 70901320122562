import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { Component, Inject, OnInit } from '@angular/core';
import { EDI_INVOICE_MODAL_TYPE } from '@app/shared/constants';
import { ShipmentsService } from '@app/shared/services/shipments.service';
import {
  AdminScopes,
  AdminUser,
  UserService,
} from '@app/shared/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggerService } from '@app/shared/services/logger.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
})
export class NotesComponent implements OnInit {
  public shipment: any;
  public adminUser: AdminUser;
  public note: string;
  public form: FormGroup;
  public isEditing: boolean = false;

  constructor(
    private shipmentsService: ShipmentsService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private snackBar: MatSnackBar,
    public drawerRef: MtxDrawerRef<NotesComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: {
      shipment: any;
      adminUser: AdminUser;
      type?: EDI_INVOICE_MODAL_TYPE;
    }
  ) {}

  ngOnInit() {
    if (
      this.data &&
      this.data.shipment &&
      this.data.adminUser &&
      this.userService.hasAuthScope(this.data.adminUser, [
        AdminScopes.MODIFY_SHIPMENTS,
      ])
    ) {
      this.shipment = this.data.shipment;
      this.adminUser = this.data.adminUser;
      this.isEditing = _.get(this.shipment, 'csNote', false);
      this.note = _.get(this.shipment, 'csNote', '');
      this.createForm();
    } else {
      this.dismissDrawer();
    }
  }

  private createForm() {
    const initialNoteValue = _.get(this.shipment, 'csNote', '');
    this.form = this.formBuilder.group({
      note: [this.note, Validators.maxLength(250)],
      doNotBillAdjustments: [
        _.get(this.shipment, 'doNotBillAdjustments', false),
      ],
    });

    const doNotBillAdjustmentsControl = this.form.get('doNotBillAdjustments');

    if (!initialNoteValue || !initialNoteValue.trim()) {
      doNotBillAdjustmentsControl.disable();
    }

    this.form.get('note').valueChanges.subscribe((noteValue) => {
      if (!noteValue.trim()) {
        doNotBillAdjustmentsControl.disable();
      } else {
        doNotBillAdjustmentsControl.enable();
      }
    });
  }

  public submitForm() {
    const noteValue = this.form.get('note').value;
    const doNotBillAdjustmentsValue = this.form.get(
      'doNotBillAdjustments'
    ).value;
    const metadata: { csNote?: string; doNotBillAdjustments?: boolean } = {
      csNote: noteValue && !_.isEmpty(noteValue) ? noteValue : null,
      doNotBillAdjustments: doNotBillAdjustmentsValue
    };
    const params = { metadata: JSON.stringify(metadata) };

    this.shipmentsService.update(this.shipment.id, params).subscribe(
      (res) => {
        this.snackBar.open('Note was saved successfully', '', {
          panelClass: 'success',
        });
        this.logger.log('Update internal shipment note success', res);
        this.dismissDrawer(true);
      },
      (err) => {
        this.snackBar.open('Saving internal note failed', '', {
          panelClass: 'error',
        });
        this.logger.error('Update internal shipment note error', err);
        this.dismissDrawer(false);
      }
    );
  }

  public dismissDrawer(successful: boolean = false): void {
    this.drawerRef.dismiss(successful);
  }
}
