import { Component, HostListener, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AdminUser, UserService } from '@app/shared/services/user.service';
import { SessionStorageService } from 'ngx-webstorage';
import { first } from 'rxjs';
import { Animations, NavItems, ResponsiveRanges, sidenavItems } from '@app/shared/constants';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-authenticated',
  animations: [
    Animations.OpenClose,
    Animations.RotateIcon,
  ],
  templateUrl: './authenticated.component.html'
})
export class AuthenticatedComponent implements OnInit{
  public user: any;
  public loading: boolean = true;
  public isExpanded: boolean = true;

  public screenWidth: any;
  public screenHeight: any;
  public showMobileMenu: boolean = true;

  public navItems: sidenavItems[] = NavItems;
  public pageTitle: string = '';

  constructor(
    private firebaseAuth: AngularFireAuth,
    public router: Router,
    private sessionStorageService: SessionStorageService,
    private drawer: MtxDrawer,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.handleScreenWidth();

    // Auto-unsubscribe from the promise once it returns the first value to avoid
    // multiple responses if we re-trigger the getAuthenticedUser() function
    this.userService.getAuthenticatedUser().pipe(first()).subscribe(
      (adminUser: AdminUser) => {
        if (adminUser) {
          this.user = adminUser;
          this.cleanNavLinks();
          this.updateNavLinks(this.router.url);
          this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
              this.updateNavLinks(event.url);
            }
          });
          this.loading = false;
        } else {
          this.router.navigate(['login']);
        }
      },
      (err) => {
        // If we cannot find your user in the system, logout of FB and display an error message
        this.firebaseAuth.signOut().then(() =>{
          this.snackBar.open('Invalid attempt to access our system', '', { panelClass: 'error' });
          this.router.navigate(['login']);
        });
      }
    );
  }

  public gotoNav(navItem: sidenavItems) {
    this.navItems.forEach(item => {
      item.active = item === navItem;
    });
    this.router.navigate([navItem.routerLink]);
  }

  private cleanNavLinks() {
    const originalNavItems = _.clone(this.navItems);
    this.navItems = originalNavItems.filter((item) => {
      return this.userService.hasAuthScope(this.user, item.scope);
    });
  }

  private updateNavLinks(routerUrl: string) {
    this.navItems.forEach(item => {
      item.active = false;
      if ((routerUrl || '').includes(item.routerLink)) {
        this.pageTitle = item.title;
        item.active = true;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.drawer.dismiss();
    this.dialog.closeAll();
    this.handleScreenWidth();
  }

  public handleScreenWidth(){
    this.isExpanded = this.screenWidth >= ResponsiveRanges.largeDesktop;
    this.showMobileMenu = this.screenWidth < ResponsiveRanges.mobile;
  }

  public toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
